import React, { useMemo, useState } from "react";
import TopSection from "../../../components/molecules/top-section/top-section.component";
import FileUpload from "../../../components/atoms/file-upload/file-upload.component";
import styles from "./import-hours.module.scss";
import Button, {
  BUTTON_TYPE_CLASSES,
} from "../../../components/atoms/button/button.component";
import styled from "styled-components";
import { Form, Formik } from "formik";
import { useStore } from "../../../../store";

import { useCreateImportHours } from "../../../../hooks/import-hours";
import { read, utils } from "xlsx";
import Papa from "papaparse";
import { useGetLogActivities } from "../../../../hooks/activity-log";
import DetailsModal from "../../../components/molecules/modals/details-modal.component";
import DetailsTable from "./details-table";
import { useNavigate } from "react-router-dom";

var topContent = {
  dashboardPage: "Import Hours",
  searchComponent: null,
  searchTitle: null,
  filterButton: false,
  timer: null,
};

export const UploadButton = styled(Button)`
  width: 213px;
  height: 49px;
  border-radius: 50px;
  align-self: left;
  margin-top: 3rem;
  margin-left: 25%;
  font-size: 18px;
  font-weight: 500;
`;

const ImportHours = () => {
  const navigate = useNavigate();
  const { rentalType } = useStore();

  const { isPending, mutate: createImportHours } = useCreateImportHours();

  const [base64String, setBase64String] = useState("");
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleFileUpload = (count) => {
    // setUploadedFileCount(count);
  };

  const {
    clientData: { activities },
  } = useGetLogActivities(rentalType);

  const handleImport = async (values, { setSubmitting, resetForm }) => {
    const { name, type } = file;
    const nameSplit = name.split(".");
    const fileName = name;
    const contentType = type;
    const fileExtension = nameSplit[1];

    try {
      if (!isPending) {
        await createImportHours({
          ...values,
          propertyType: rentalType,
          supportingDocuments: [
            {
              fileName: fileName,
              fileExtension: fileExtension,
              contentType: contentType,
              data: base64String,
            },
          ],
        });
        resetForm();
        navigate("/logs");
      }
    } catch (error) {
      throw error;
    } finally {
      setSubmitting(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.value;
    setFile(e.target.value);
    convertFileToBase64(e.target.value);

    if (file) {
      setFile(file);
      if (file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
        parseExcel(file);
      } else if (file.name.endsWith(".csv")) {
        parseCsv(file);
      }
    }
  };

  const parseExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet, { header: 1 });
      setHeaders(json[0]);
      setData(json.slice(1));
    };
    reader.readAsArrayBuffer(file);
  };

  const parseCsv = (file) => {
    Papa.parse(file, {
      complete: (result) => {
        setHeaders(result.data[0]);
        setData(result.data.slice(1));
      },
    });
  };

  const convertFileToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64String(reader.result.split(",")[1]);
    };
    reader.onerror = (error) => {
      console.error("Error converting file to Base64:", error);
    };
  };

  const downloadTemplate = async () => {
    // Generate the file and download
    const response = await fetch(
      "/template/750hrsTracker_ActivityLogImportTemplate.csv"
    );

    const href = URL.createObjectURL(await response.blob());
    const link = document.createElement("a");
    const contentDisposition = response.headers.get("Content-Disposition");

    let fileName = "750hrsTracker_ActivityLogImportTemplate";

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename=([^;]*)/);
      if (fileNameMatch && fileNameMatch.length > 0) {
        fileName = fileNameMatch[1];
      }
    }

    link.href = href;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const handleModalCancel = (modal) => {
    modal.hide();
    setShowModal(false);
  };

  const previewActivityAndTaskValues = async () => {
    setShowModal(true);
  };

  const detailsModalProp = useMemo(() => {
    if (showModal) {
      return (
        <DetailsModal
          showModal={showModal}
          handleCancel={handleModalCancel}
          details={() => <DetailsTable data={activities} />}
        />
      );
    }
  }, [showModal, activities]);
  return (
    <>
      {detailsModalProp}
      <div className={styles["import__main--div"]}>
        <TopSection content={topContent} />
        <div className={styles["import__div"]}>
          <div className="download-template flex flex-row justify-end w-full text-right">
            <button
              buttonType={BUTTON_TYPE_CLASSES.curved}
              onClick={previewActivityAndTaskValues}
              type="submit"
              className="bg-business-primary hover:bg-business-secondary text-white font-semibold px-4 py-2 rounded-lg mr-2"
            >
              View Activities & Tasks Values
            </button>
            <button
              buttonType={BUTTON_TYPE_CLASSES.curved}
              onClick={downloadTemplate}
              type="submit"
              className="bg-business-primary hover:bg-business-secondary text-white font-semibold px-4 py-2 rounded-lg"
            >
              Download Template
            </button>
          </div>
          <Formik initialValues={{ upload: "" }} onSubmit={handleImport}>
            {({ values, isSubmitting, handleChange }) => (
              <Form className={styles["entry__form"]}>
                <div>
                  <h4>Upload hours</h4>
                </div>
                <div className={styles["import__upload--div"]}>
                  <FileUpload
                    fileType="Accepted Format: .csv"
                    onFileUpload={handleFileUpload}
                    name="upload"
                    fileMax={1}
                    handleChange={handleFileChange}
                    accept=".csv"
                  />
                  <UploadButton
                    buttonType={BUTTON_TYPE_CLASSES.curved}
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    Submit
                  </UploadButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="file-content-preview p-6">
          <h2 className="font-semibold text-xl">File Upload Preview</h2>
          {data.length > 0 && (
            <div className="mt-4 overflow-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    {headers.map((header, index) => (
                      <th
                        key={index}
                        className="px-4 py-2 border-b border-gray-200 text-left text-sm font-medium text-gray-600"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, rowIndex) => (
                    <tr key={rowIndex} className="hover:bg-gray-50">
                      {row.map((cell, cellIndex) => (
                        <td
                          key={cellIndex}
                          className="px-4 py-2 border-b border-gray-200 text-sm text-gray-700"
                        >
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ImportHours;

export const claimData = [
  [
    {
    index: 1,
    title: "Who can claim REPS?",
    content:
      "<ul><li> • You must spend >750 hours in qualified real estate activities per fiscal year.</li><li> • You must spend more time in real estate than any of your other jobs combined.</li><li> • At least 500 of the 750 hours must be spent materially participating in rental properties that you own.</li></ul><p></p>*Note: the categories available in my750hrstracker are suggestions only. Some CPAs will agree and some will disagree. We advise you to log MORE than the bare minimum hours in the case that some of these categories are not deemed acceptable in an audit.*"
  },
  {
    index: 2,
    title: "Will I have a tax benefit by claiming REPS?",
    content:
      "You might if you have >$150,000 adjusted gross income + rental investment losses.<p></p> this case, claiming REPS will allow you to use rental losses to offset W2 and other income (including your spouse's income if filing jointly)"
  }
],
[
    {
        index: 3,
        title: "Important points when filing your tax return",
        content:
          "Occupation: your occupation should be listed as Real Estate Professional<p></p> an election should be included with your tax return to aggregate your rental properties as a single activity.  This will allow you to add your hours spent on each rental property.<p></p> mark each rental property as REPS to have those losses offset W2 income<p></p>Questions?<p></p> to a CPA knowledgeable in real estate for clarification"
      },
      {
        index: 4,
        title: "What is the most important part of claiming REPS?",
        content:
          "Clear and consistent DOCUMENTATION of your REPS activity time in real time, along with maintaining supporting evidence of those activities...<p></p> your REPS hours up to date with my750hrstracker!  Continue to see tips on using this app.<p></p> LLC and its owners, presenters, and employees are not in the business of providing personal, financial, tax, legal or investment advice and specifically disclaims any liability, loss or risk, which is incurred as a consequence, either directly or indirectly, by the use of any of the information contained in this app. <p></p> its website, and any online tools, if any, do NOT provide ANY legal, accounting, securities, investment, tax or other professional services advice and are not intended to be a substitute for meeting with professional advisors. <p></p> legal advice or other expert assistance is required, the services of competent, licensed and certified professionals should be sought. In addition, my750hrstracker does not endorse ANY specific investments, investment strategies, advisors, or financial service firms.*"
      }
]
];


export const usingData = [
  {
    index: 1,
    title: "Adding properties",
    content:
      "Enter each of your rental properties into the Property page.  A 'nickname' can be added as a quick way to search for your properties in other screens. <p> <p> Tip: Add a property nickname labeled 'Prospective Properties' as a way to bookmark activities that involve properties you have not yet purchased.  You can go back and edit the nickname after you've finalized the purchase of that property.",
  },
  {
    index: 2,
    title: "Adding entry",
    content:
      "Every time you spend REPS eligible time, enter a new time log. <p> <p> For hours spent on properties you do not yet own, choose 'prospective properties' and 'general real estate hours' as a placeholder.  Make sure to include the property address in your description.",
  },
  {
    index: 3,
    title: "Attaching evidence",
    content:
      "Upload supporting evidence for each time log.  This can include pictures of receipts, leaky faucets, screenshots of call logs... anything that will support your claim of this activity! <p> <p> * The more supporting evidence you have, the stronger your claim for REPS*",
  },
  {
    index: 4,
    title: "Exporting data, exclusive to the mobile app",
    content:
      "Your data is stored in the cloud so if you lose your phone you won't lose your data! <p> <p> We here at REPStracker are big fans of being cautious:  you should export/print your data throughout the year so that you have a backup copy",
  },
];

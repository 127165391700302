import React, { useCallback, useMemo, useState } from "react";
import styles from "./properties-icon-wrapper.module.scss";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin5Line } from "react-icons/ri";
import {
  useDeleteProperty,
  useEditProperty,
} from "../../../../hooks/properties";
import PropertyModal from "../../../modals/property/property-modal.component";
import { useStore } from "../../../../store";
import {
  PROPERTY_DELETE,
  PROPERTY_UPDATE,
} from "../../../../constants/permissions";
import ConfirmationModal from "../../molecules/modals/confirmation-modal.component";

const PropertiesIconWrapper = ({ data }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { isPending: isPropertyEditing, mutate: editProperty } =
    useEditProperty();
  const { isPending: isPropertyDeleting, mutate: deleteProperty } =
    useDeleteProperty();
  const [show, setShow] = useState(false);
  const { userPermissions } = useStore();

  const handleEdit = () => {
    setShow(true);
  };

  const handleDelete = () => {
    setShowConfirmationModal(true);
  };

  const handleShowClose = () => {
    setShow(false);
  };

  const handleModalOk = useCallback(
    (modal) => {
      !isPropertyDeleting && deleteProperty(data?.id);
      modal.hide();
      setShowConfirmationModal(false);
    },
    [data, deleteProperty, isPropertyDeleting]
  );

  const handleModalCancel = (modal) => {
    modal.hide();
    setShowConfirmationModal(false);
  };

  const confirmationModalProp = useMemo(() => {
    if (showConfirmationModal) {
      return (
        <ConfirmationModal
          showModal={showConfirmationModal}
          handleOk={handleModalOk}
          handleCancel={handleModalCancel}
          confirmationQuestion="Are you sure you want to delete this property?"
        />
      );
    }
  }, [showConfirmationModal, handleModalOk]);

  const hasPermission = useCallback(
    (permission) => {
      return userPermissions.some((p) => p.value === permission) || false;
    },
    [userPermissions]
  );

  const editFormData = {
    id: data?.id,
    name: data?.name,
    address: data?.address,
    propertyType: data?.propertyType,
  };

  return (
    <>
      {confirmationModalProp}
      <div className={styles.iconsWrapper}>
        {show && (
          <PropertyModal
            handleAction={!isPropertyEditing && editProperty}
            handleClose={handleShowClose}
            formData={editFormData}
            isEdit={true}
          />
        )}
        {hasPermission(PROPERTY_UPDATE) && (
          <div className={styles.icon}>
            <TbEdit color="#FF8300" onClick={handleEdit} />
          </div>
        )}
        {hasPermission(PROPERTY_DELETE) && (
          <div className={styles.icon}>
            <RiDeleteBin5Line color="#F12525" onClick={handleDelete} />
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(PropertiesIconWrapper);

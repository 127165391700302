import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import styles from "./property-modal.module.scss";
import FormDropDown from "../../components/atoms/form-dropdown/form-dropDown.component";
import PopupLayout from "../../layouts/popup.layout/property-modal/popup-modal.component";
import { nameValidator } from "../../../utils/validation/validation.utils";
import AddressSuggestionInput from "../../components/atoms/autosuggest/address-autosuggest.component";
import { useStore } from "../../../store";
import FormInput from "../../components/atoms/form-input/form-input.component";
import { showToast } from "../../components/atoms/showToast/showToast";
import { NotificationTypes } from "../../constants";

var optionList = [
  {
    id: 1,
    address: "STR",
    myValue: "str",
  },
  {
    id: 2,
    address: "LTR",
    myValue: "ltr",
  },
];

var formContent = {
  heading: "Add new property",
  buttonText: "Save",
  personalGrid: null,
  form: [],
};

const validate = Yup.object({
  // address: GeneralValidator,
  name: nameValidator("Property Name"),
});

const PropertyModal = ({ handleClose, handleAction, formData, isEdit }) => {
  // geet property details
  const [address, setAddress] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [propertyTypeValue, setPropertyType] = useState("");

  const { rentalType } = useStore();
  useEffect(() => {
    if (isEdit) {
      formContent.heading = "Edit Property";
      setPropertyName(formData?.name);
      setAddress(formData?.address);
      setPropertyType(rentalType);
    }
  }, [isEdit, formData, rentalType]);

  const handleNextPage = async (values, { setSubmitting, resetForm }) => {
    const data = isEdit ? { ...values, id: formData?.id } : { ...values };

    data.address = address;
    data.propertyType = propertyTypeValue;

    if (!address) {
      showToast("Address is required", NotificationTypes.ERROR);
      return;
    }
    try {
      await handleAction(data, {
        onSuccess: () => {
          resetForm();
          handleClose();
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleValueChange = useCallback((value) => {
    setAddress(value);
  }, []);
  const handlePropertTypeChange = useCallback((value) => {
    setPropertyType(value);
  }, []);

  const handlePropertyNameChange = useCallback((event) => {
    setPropertyName(event.target.value);
  }, []);

  return (
    <div className={styles["container"]}>
      <PopupLayout
        content={formContent}
        Children={
          <>
            <AddressSuggestionInput
              inputName="address"
              labelName="Enter house address"
              handlChange={handleValueChange}
              initialValue={address}
            />
            <FormInput
              className="w-[90%] h-12 mt-2 mb-4 px-2 rounded-md"
              label="Enter a name for your property"
              name="name"
              placeholder="Name of property"
              type="text"
              value={propertyName}
              changeFn={handlePropertyNameChange}
            />
            <FormDropDown
              optionList={optionList}
              labelName="Select property type"
              initialName="propertyType"
              className="w-full"
              value={propertyTypeValue}
              changeFn={handlePropertTypeChange}
            />
          </>
        }
        validationSchema={validate}
        initialValue={{
          address: formData?.address || "",
          name: formData?.name || "",
          propertyType: propertyTypeValue,
        }}
        handleSubmit={handleNextPage}
        handleClose={handleClose}
        // error={authStore.errorMessage}
      />
    </div>
  );
};

PropertyModal.defaultProps = {
  isEdit: false,
};

PropertyModal.propTypes = {
  isEdit: PropTypes.bool,
};

export default PropertyModal;

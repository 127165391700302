import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { AuthButton } from "./auth.styles";
import { Form, Formik } from "formik";
import FormInput from "../../components/atoms/form-input/form-input.component";
import { BUTTON_TYPE_CLASSES } from "../../components/atoms/button/button.component";
import BackArrow from "../../components/atoms/back-arrow/back-arrow.component";
import ErrorModal from "../../components/atoms/error-modal/error-modal.component";
import { useNavigate } from "react-router-dom";

const layoutImage = "/img/bg_auth_layout.jpg";
const logoImage =
  "https://res.cloudinary.com/dftu6mjmt/image/upload/v1710765782/750HoursTracker/tracker-logo_copq4t.png";

const AuthLayout = observer(
  ({
    content,
    validationSchema,
    handleSubmit,
    initialValue,
    error,
    PostChildren,
    Children,
    isLoading,
  }) => {
    let navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      if (error) {
        setShowModal(true);
      }
    }, [error]);

    const handleShowClose = () => {
      setShowModal(false);
    };

    return (
      <div className="w-full m-0 p-0 flex flex-row h-auto">
        <div className="layout-img-container hidden xl:block xl:w-3/5">
          <div className="w-full">
            <img
              src={layoutImage}
              alt="750HrsTracker Layout"
              className="object-fill w-full h-full"
            />
          </div>
        </div>
        <div className="layout-img-container w-full xl:w-2/5 mx-auto p-4 md:p-8 bg-[#F2F2FF] min-h-screen pb-16 flex flex-col">
          <div className="flex-grow">
            {content.backButton && (
              <BackArrow
                handleReturn={() => navigate(-1)}
                title={content.backButton}
              />
            )}
            <div className="logo-container w-3/5 mx-auto flex justify-center md:my-16 mb-8 mt-10">
              <img
                className="object-fill md:h-24 h-16"
                src={logoImage}
                alt="750HrsTracker Logo"
              />
            </div>
            {content.heading && (
              <h2 className="font-semibold text-2xl text-center mb-4">
                {content.heading}
              </h2>
            )}
            {content.message && (
              <div>
                <p className="text-750hrs-form-label font-light text-center md:my-4 my-2">
                  {content.message}
                </p>
              </div>
            )}
            <div className="relative">
              <div class="absolute top-10 left-0 md:left-24 -mx-10 -my-8 rounded-full border-4 border-business-primary h-24 w-24 ring-4 ring-business-primary z-0"></div>
              <div className="relative w-3/4 md:w-3/5 mx-auto bg-white rounded-xl md:rounded-3xl p-8 md:p-12 z-10">
                <Formik
                  initialValues={initialValue}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    handleSubmit(values, { setSubmitting, resetForm });
                  }}
                >
                  {({ values, isSubmitting }) => (
                    <Form>
                      {showModal && (
                        <ErrorModal
                          errorText={error}
                          handleClose={handleShowClose}
                        />
                      )}
                      {PostChildren}
                      {content &&
                        content.form.map((el, i) => {
                          const { label, name, placeholder, type } = el;
                          return (
                            <FormInput
                              className="mb-4 w-full p-4 m-0 text-black rounded-md bg-[#F0F5FD] border-0 shadow-sm"
                              label={label}
                              name={name}
                              placeholder={placeholder}
                              type={type}
                              required
                              key={i}
                            />
                          );
                        })}
                      {Children}
                      {content.buttonText && (
                        <AuthButton
                          buttonType={BUTTON_TYPE_CLASSES.curved}
                          isLoading={isLoading}
                          type="submit"
                          className="auth--button"
                        >
                          {content.buttonText}
                        </AuthButton>
                      )}
                      {content.subQuestion && (
                        <div className="text-center my-4 flex flex-col md:flex-row justify-center">
                          {content.subQuestion}
                          <span className="underline text-business-primary font-semibold ml-2">
                            <a href={content.subLink}>{content.subOption}</a>
                          </span>
                        </div>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
              <div class="absolute bottom-10 right-0 md:right-24 -my-8 rounded-full border-4 border-business-secondary h-24 w-24 ring-4 ring-business-secondary z-0"></div>
            </div>
            {/* <AuthCircleTwo></AuthCircleTwo> */}
          </div>
        </div>
      </div>
    );
  }
);

export default AuthLayout;

import React from "react";
import AuthLayout from "../../../layouts/auth.layout";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useCreateInvitedUser } from "../../../../hooks/auth";
import styles from "./invited-user.module.scss";
import {
  nameValidator,
  EmailValidator,
  PasswordValidator,
  ConfirmPasswordValidator,
} from "../../../../utils/validation/validation.utils";

var formContent = {
  backButton: "Back",
  heading: "Complete Invitation",
  message: "Enter your details and invitation code",
  buttonText: "Submit",
  subQuestion: "Already have an account?",
  subOption: "Sign in",
  subLink: "/login",
  successMessage: "Invitation successful. Proceed to login",
  personalGrid: null,
  form: [
    {
      label: "First Name",
      placeholder: "Enter your first name",
      type: "text",
      name: "firstName",
    },
    {
      label: "Last Name",
      placeholder: "Enter your last name",
      type: "text",
      name: "lastName",
    },
    {
      label: "Password",
      placeholder: "Enter your password",
      type: "password",
      name: "password",
    },
    {
      label: "Confirm Password",
      placeholder: "Confirm your password",
      type: "password",
      name: "confirmPassword",
    },
    {
      label: "Invitation Code",
      placeholder: "Enter your invitation code",
      type: "text",
      name: "invitationCode",
    },
  ],
};

const defaultFormFields = {
  firstName: "",
  lastName: "",
  password: "",
  confirmPassword: "",
};

const validate = Yup.object({
  firstName: nameValidator("First Name"),
  lastName: nameValidator("Last Name"),
  password: PasswordValidator,
  confirmPassword: ConfirmPasswordValidator,
});
const CreateInvitedUser = () => {
  const navigate = useNavigate();
  const { mutate: createInvitedUser, isPending } = useCreateInvitedUser();

  const handleNextPage = async (values, { setSubmitting, resetForm }) => {
    try {
      !isPending &&
        createInvitedUser(values, {
          onSuccess: () => {
            navigate("/login");
            resetForm();
          },
        });
    } catch (error) {
      throw error;
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={styles["container"]}>
      <AuthLayout
        content={formContent}
        validationSchema={validate}
        initialValue={defaultFormFields}
        handleSubmit={handleNextPage}
        isLoading={isPending}
      />
    </div>
  );
};

export default CreateInvitedUser;

import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import base from "../../../../libs/axios";
// import https from "https";

const AddressSuggestionInput = ({
  initialValue,
  inputName,
  labelName,
  handlChange,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const fetchSuggestions = async ({ value }) => {
    if (!value) return setSuggestions([]);
    base
      .get("/api/public/autocomplete/" + value)
      .then(({ data }) => {
        if (data?.data?.predictions) {
          setSuggestions(
            data?.data?.predictions.map((prediction) => ({
              description: prediction.description,
              placeId: prediction.place_id,
            }))
          );
        }
      })
      .catch((err) => {
        console.error(
          "Error fetching suggestions:",
          err?.response?.data?.message || err?.message
        );
        throw err;
      });
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    fetchSuggestions({ value });
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion.description;

  const renderSuggestion = (suggestion) => <div>{suggestion.description}</div>;
  const handleAutoFillChange = (event, { newValue }) => {
    setInputValue(newValue);
    handlChange(newValue);
  };

  return (
    <div className="flex flex-col">
      <h3 className="text-ray-200">{labelName}</h3>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          placeholder: "Enter an address",
          value: inputValue,
          onChange: handleAutoFillChange,
          className: "w-[90%] rounded-md h-12 my-4",
          name: inputName,
        }}
      />
    </div>
  );
};

export default AddressSuggestionInput;

import React, { useCallback } from "react";
import ToggleSwitch from "../../atoms/toggle/toggle-switch.component";
import Search from "../../atoms/search/search.component";
import FilterButton from "../../atoms/filter-button/filter-button.component";
import TimerToggle from "../../atoms/timer-toggle/timer-toggle.component";

const TopSection = ({ content, handleSearch, handleFilter }) => {
  const searchFn = useCallback(
    (data) => {
      if (handleSearch) {
        handleSearch(data);
      }
    },
    [handleSearch]
  );
  const filterFn = useCallback(
    (data) => {
      if (handleFilter) {
        handleFilter(data);
      }
    },
    [handleFilter]
  );

  return (
    <div className="relative flex flex-col mdflex-row-reverse flex-1 flex-shrink-0 p-4">
      <div className="flex w-full justify-end text-right">
        <ToggleSwitch />
      </div>
      <div className="flex flex-col md:flex-row w-full  md:w-2/3">
        <div className="w-full">
          {content?.dashboardPage ? (
            <h4 className="text-xl md:2xl font-semibold my-2 text-left">
              {content?.dashboardPage}
            </h4>
          ) : (
            ""
          )}
        </div>
        <div className="w-full flex flex-col md:flex-row gap-4">
          {/* {content.timer ? <TimerToggle /> : null} */}
          {content?.searchComponent ? (
            <Search
              searchTitle={content?.searchTitle}
              handleSearch={searchFn}
            />
          ) : null}
          {content?.filterButton ? (
            <FilterButton handleFilter={handleFilter} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TopSection;

import React, { useCallback, useMemo, useState } from "react";
import styles from "./logs-icon-wrapper.module.scss";
import { RiDeleteBin5Line, RiErrorWarningLine } from "react-icons/ri";
import PropertyModal from "../../../modals/property/property-modal.component";
import { useDeleteLog, useEditLog } from "../../../../hooks/activity-log";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../molecules/modals/confirmation-modal.component";
import { useStore } from "../../../../store";
import {
  ACTIVITYLOG_DELETE,
  ACTIVITYLOG_VIEW,
} from "../../../../constants/permissions";
import Spinner from "../spinner/spinner.component";

const LogsIconWrapper = ({ data, isDashboard }) => {
  const { isPending: isLogEditing, mutate: editLog } = useEditLog();
  const { isPending: isLogDeleting, mutate: deleteLog } = useDeleteLog();
  const [show, setShow] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();

  const { userPermissions, isPermissionsLoading } = useStore();

  const handleShowLog = () => {
    navigate(`/logs/${data?.id}`);
  };

  const handleEdit = () => {
    setShow(true);
  };

  const handleDelete = () => {
    setShowConfirmationModal(true);
  };

  const handleModalOk = useCallback(
    (modal) => {
      !isLogDeleting && deleteLog(data?.id);
      modal.hide();
      setShowConfirmationModal(false);
    },
    [data, isLogDeleting, deleteLog]
  );

  const handleModalCancel = (modal) => {
    modal.hide();
    setShowConfirmationModal(false);
  };

  const handleShowClose = () => {
    setShow(false);
  };

  const hasPermission = useCallback(
    (permission) => {
      return userPermissions.some((p) => p.value === permission) || false;
    },
    [userPermissions]
  );

  const confirmationModalProp = useMemo(() => {
    if (showConfirmationModal) {
      return (
        <ConfirmationModal
          showModal={showConfirmationModal}
          handleOk={handleModalOk}
          handleCancel={handleModalCancel}
          confirmationQuestion="Are you sure you want to delete this log?"
        />
      );
    }
  }, [showConfirmationModal, handleModalOk]);

  const editFormData = {
    id: data?.id,
    name: data?.name,
    address: data?.address,
  };

  if (isPermissionsLoading) {
    return <Spinner />;
  }
  return (
    <>
      {confirmationModalProp}
      <div className={styles.iconsWrapper}>
        {show && (
          <PropertyModal
            handleAction={!isLogEditing && editLog}
            handleClose={handleShowClose}
            formData={editFormData}
            RiErrorWarningLine
            color="#003889"
          />
        )}
        {hasPermission(ACTIVITYLOG_VIEW) && (
          <div className={styles.icon}>
            <RiErrorWarningLine color="#003889" onClick={handleShowLog} />
          </div>
        )}
        {hasPermission(ACTIVITYLOG_DELETE) && (
          <>
            <div className={styles.icon}>
              <RiDeleteBin5Line color="#F12525" onClick={handleDelete} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(LogsIconWrapper);

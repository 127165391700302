import React, { useCallback, useEffect, useState } from "react";
import TopSection from "../../../components/molecules/top-section/top-section.component";
import styles from "./properties.module.scss";
import Button, {
  BUTTON_TYPE_CLASSES,
} from "../../../components/atoms/button/button.component";
import { HiPlusCircle } from "react-icons/hi";
import styled from "styled-components";
import UserContext from "../../../../hooks/userContext";
import PropertyModal from "../../../modals/property/property-modal.component";
import { useStore } from "../../../../store";
import {
  useCreateProperty,
  useDeleteProperty,
  useGetProperties,
  useGetSomeProperties,
} from "../../../../hooks/properties";
import TableWrapper from "../../../components/molecules/tables/table-wrapper/table-wrapper.component";
import {
  mobilePropertiesColumns,
  propertiesColumns,
  renderMobilePropertiesCell,
  renderMobilePropertiesHeader,
  renderMobilePropertiesIcon,
} from "./data";
import Spinner from "../../../components/atoms/spinner/spinner.component";
import { PROPERTY_CREATE } from "../../../../constants/permissions";

var topContent = {
  dashboardPage: "Properties",
  searchComponent: true,
  searchTitle: "Search property",
  filterButton: false,
  timer: null,
};

export const AddButton = styled(Button)`
  width: 50px;
  height: 49px;
  border-radius: 50px;
  align-self: left;
  margin-top: 3rem;
  margin-left: 35%;
  font-size: 18px;
  font-weight: 500;
`;

const Properties = () => {
  const { rentalType, userPermissions } = useStore();
  const [show, setShow] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const [searchKeyword, setsearchKeyword] = useState("");
  const [propertiesTransformed, setPropertiesTransformed] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const { isLoading, clientData: data } = useGetProperties({
    pageNumber,
    pageSize,
    propertyType: rentalType,
  });
  const { clientData: someProperties } = useGetSomeProperties(
    searchKeyword,
    rentalType.toUpperCase()
  );

  const { mutate, isPending } = useCreateProperty();
  const { isPending: isPropertyDeleting } = useDeleteProperty();

  const { properties, count, currentPage } = data || {};

  const hasPermission = useCallback(
    (permission) => {
      return userPermissions.some((p) => p.value === permission) || false;
    },
    [userPermissions]
  );
  useEffect(() => {
    setPropertiesTransformed(properties);
    setPageSize(5);
  }, [properties]);

  const handleShow = () => {
    setShow(true);
  };

  const handleShowClose = () => {
    setShow(false);
  };

  const handlePageNavigation = (page) => {
    setPageNumber(page);
  };

  const handleSearch = async (keyword) => {
    try {
      setPageLoading(true);
      setsearchKeyword(keyword);
      let props = keyword ? someProperties.someProperties : properties;
      setPropertiesTransformed(props ?? []);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setPageLoading(false);
    }
  };

  if (isPageLoading) {
    return <Spinner />;
  }

  return (
    <UserContext.Provider value={handleShow}>
      {show && (
        <PropertyModal
          handleAction={!isPending && mutate}
          handleClose={handleShowClose}
          isEdit={false}
        />
      )}

      <div className={styles["property__container"] + " md:h-screen"}>
        <div>
          <TopSection content={topContent} handleSearch={handleSearch} />
        </div>
        <div className="my-10 bg-white p-8 rounded-lg">
          <div>
            <TableWrapper
              currentPage={parseInt(currentPage)}
              dataSource={propertiesTransformed}
              desktopColumns={propertiesColumns}
              handlePageNavigation={handlePageNavigation}
              loading={isLoading || isPropertyDeleting}
              mobileColumns={mobilePropertiesColumns}
              pageSize={pageSize}
              renderItemHeaderCell={renderMobilePropertiesHeader}
              renderItemValueCell={renderMobilePropertiesCell}
              renderMobileIcon={renderMobilePropertiesIcon}
              totalCount={count}
            />

            {hasPermission(PROPERTY_CREATE) && (
              <div className="w-full md:w-1/4 text-center mx-auto">
                <Button
                  buttonType={BUTTON_TYPE_CLASSES.curved}
                  // isLoading={isSubmitting}
                  onClick={handleShow}
                  type="submit"
                  className="my-16 rounded-lg"
                >
                  <HiPlusCircle />
                  Add Property
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </UserContext.Provider>
  );
};

export default Properties;

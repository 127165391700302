/* eslint-disable camelcase */

import LogsIconWrapperComponent from "../../../components/atoms/logs-icon-wrapper/logs-icon-wrapper.component";
import columnStyles from "../../../components/molecules/tables/table-wrapper/column-styles.module.scss";

export const logsColumns = [
  {
    title: <p className={columnStyles.table_title}>Date</p>,
    dataIndex: "activityDate",
    key: "activityDate",
    render: (activityDate) => (
      <div className={columnStyles.table_content}>{activityDate || "N/A"}</div>
    ),
  },

  {
    title: <p className={columnStyles.table_title}>Activity</p>,
    dataIndex: "activity",
    key: "activity",
    render: (activity) => (
      <div className={columnStyles.table_content}>{activity || "N/A"}</div>
    ),
  },

  {
    title: <p className={columnStyles.table_title}>Time Spent</p>,
    dataIndex: "timeSpent",
    key: "timeSpent",
    render: (timeSpent) => (
      <div className={columnStyles.table_content}>{timeSpent || "N/A"}</div>
    ),
  },
  {
    title: <p className={columnStyles.table_title}>Service Type</p>,
    dataIndex: "serviceType",
    key: "serviceType",
    render: (serviceType) => (
      <div className={columnStyles.table_content}>{serviceType || "N/A"}</div>
    ),
  },

  {
    dataIndex: "id",
    key: "id",
    render: (id, record) => {
      return (
        <div className={columnStyles.table_contentAction}>
          <LogsIconWrapperComponent data={record} id={id} isDashboard={false} />
        </div>
      );
    },
  },
];

export const mobileLogsColumns = [
  { key: "activityDate", label: "Date" },
  { key: "activity", label: "Activity" },
  { key: "timeSpent", label: "Time Spent" },
  { key: "serviceType", label: "Service Type" },
  { key: "id", label: "" },
];

export const renderMobileLogsHeader = (header) => header?.label;

export const renderMobileLogsCell = (header, item) => item[header.key];

export const renderMobileLogsIcon = (item) => {
  return <LogsIconWrapperComponent data={item} id={item?.id} />;
};

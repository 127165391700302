import { useEffect, useState } from "react";

export const useWindowSize = () => {
  const [size, setSize] = useState({
    width: window?.innerWidth,
    height: window?.innerWidth,
  });

  useEffect(() => {
    if (window) {
      const updateSize = () =>
        setSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });

      window.addEventListener("resize", updateSize);
      updateSize();

      return () => window.removeEventListener("resize", updateSize);
    }
  }, []);

  return size;
};

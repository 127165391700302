import React from "react";

const DetailsTable = ({ data }) => {
  const renderRows = (items, level = 0) => {
    return items.map((item) => (
      <React.Fragment key={item.id}>
        <tr
          style={{ paddingLeft: level * 20 }}
          className="border border-1 border-business-primary"
        >
          <td>
            <div className="flex flex-col">
              <span>{item.name}</span>
              <span className="font-semibold text-business-primary capitalize">
                {item.category}
              </span>
            </div>
          </td>
          <td>{item.slug}</td>
          <td>
            {item.tasks &&
              item.tasks.length > 0 &&
              renderRows(item.tasks, level + 1)}
          </td>
        </tr>
      </React.Fragment>
    ));
  };

  return (
    <table border="1" cellPadding="10" className="border border-1">
      <thead>
        <tr>
          <th>Name</th>
          <th>Value</th>
          <th>Tasks</th>
        </tr>
      </thead>
      <tbody>{renderRows(data)}</tbody>
    </table>
  );
};

export default DetailsTable;

import base from "../../libs/axios";

export const updateUserDetails = (data) => {
  const { id, firstname, lastname, phone } = data;

  return base
    .patch(`/api/users/${id}`, { firstname, lastname, phoneNumber: phone })
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error updating user details:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const updateUserPhoto = (data) => {
  const { id, profilePicture } = data;
  return base
    .patch(`/api/users/${id}/update-profile-pic`, {
      profilePicture: profilePicture ? profilePicture[0] : {},
    })
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error updating user profile photo:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const switchTeam = (data) => {
  const { id, toTeamId } = data;
  return base
    .patch(`/api/users/${id}/switch-team`, {
      toTeamId,
    })
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error switching user team:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const getUser = () => {
  return base
    .post(`/api/users/me`)
    .then(({ data }) => data?.data)
    .catch((err) => {
      console.error(
        "Error retrieving user details:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const getUserPermissions = () => {
  return base
    .get(`/api/users/get-permissions`)
    .then(({ data }) => data?.data)
    .catch((err) => {
      console.error(
        "Error retrieving user details:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

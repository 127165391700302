export const DASHBOARD_VIEW = "Permissions.Dashboard.View";

export const ACTIVITYLOG_CREATE = "Permission.ActivityLog.Create";
export const ACTIVITYLOG_IMPORT = "Permission.ActivityLog.Import";
export const ACTIVITYLOG_DELETE = "Permission.ActivityLog.Delete";
export const ACTIVITYLOG_VIEW = "Permission.ActivityLog.View";
export const ACTIVITYLOG_UPDATE = "Permission.ActivityLog.Update";

export const PROPERTY_VIEW = "Permission.Property.View";
export const PROPERTY_CREATE = "Permission.Property.Create";
export const PROPERTY_UPDATE = "Permission.Property.Update";
export const PROPERTY_DELETE = "Permission.Property.Delete";
export const PROPERTY_ASSIGN = "Permission.Property.AssignToUsers";

export const SUBSCRIPTION_MANAGE = "Permission.Subscription.Manage";

export const TEAM_INVITE_USER = "Permission.Team.InviteUser";
export const TEAM_GET_MEMBERS = "Permission.Team.GetUsers";

export const USER_CREATE = "Permissions.User.Create";
export const USER_EDIT = "Permissions.User.Edit";
export const USER_UPDATE = "Permissions.User.Update";
export const USER_DELETE = "Permissions.User.Delete";
export const USER_RESET = "Permissions.User.Reset";
export const USER_UPDATE_PROFILE = "Permissions.Users.UpdateProfile";
export const USERS_CREATE = "Permissions.Users.Create";
export const USERS_VIEW = "Permissions.Users.View";
export const USERS_UPDATE = "Permissions.Users.Update";
export const USERS_DELETE = "Permissions.Users.Delete";
export const USERS_SWITCH = "Permissions.Users.Switch";

export const NO_PERMISSION = "none";

export const memberData = [
  {
    index: 1,
    title: "Connect with fellow real estate professionals",
    content:
      "<ul><li>• Network and build relationships with like-minded individuals.</li><li>• Expand your professional network and gain valuable insights from experienced peers.</li><li>• Share your experiences, challenges, and successes with a supportive community.</li></ul>",
  },
  {
    index: 2,
    title: "Access exclusive content and resources",
    content:
      "<ul><li>• Get early access to new features and updates.</li><li>• Gain valuable knowledge from industry experts through webinars, workshops, and events.</li><li>• Download helpful templates, guides, and checklists to optimize your workflow.</li></ul>",
  },
  {
    index: 3,
    title: " Ask questions and get expert advice",
    content:
      "<ul><li>• Receive personalized support from our dedicated customer support team.</li><li>• Connect with other users in our forum and ask questions about REPS compliance, tax strategies, and more.</li><li>• Learn from the experiences of others and share your own expertise.</li></ul>",
  },
  {
    index: 4,
    title: "Stay informed about the latest industry trends:",
    content:
      "<ul><li>• Receive regular updates on important industry news and regulations.</li><li>• Gain valuable insights from our team of experts on the latest trends and best practices in real estate.</li><li>• Stay ahead of the curve and make informed decisions about your business.</li></ul>",
  },
  {
    index: 5,
    title: "Participate in discussions and share your knowledge",
    content: "<ul><li>• Engage in meaningful conversations and exchange ideas with other members.</li><li>• Contribute your expertise and help others navigate the challenges of real estate.</li><li>• </li>Shape the future of my750hrstracker by sharing your feedback and suggestions.</li></ul>"
  },
];

import { Form, Formik } from "formik";
import React from "react";
import {
  ConsentValidator,
  EmailValidator,
  GeneralValidator,
  nameValidator,
} from "../../../../utils/validation/validation.utils";
import * as Yup from "yup";
import styled from "styled-components";
import Button, {
  BUTTON_TYPE_CLASSES,
} from "../../../components/atoms/button/button.component";
import styles from "./support.module.scss";
import PhoneInput from "../../../components/atoms/phone-input/phone-input.component";
import TopSection from "../../../components/molecules/top-section/top-section.component";
import { useSendSupportMessage } from "../../../../hooks/support";
import FormInput from "../../../components/atoms/form-input/form-input.component";
import { useNavigate } from "react-router-dom";

var topContent = {
  dashboardPage: "Support",
  searchComponent: null,
  searchTitle: null,
  filterButton: null,
  timer: null,
};

const defaultFormFields = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  message: "",
  agreement: false,
};

const validate = Yup.object({
  firstName: nameValidator("First Name"),
  lastName: nameValidator("Last Name"),
  email: EmailValidator,
  message: GeneralValidator,
  agreement: ConsentValidator,
});

const Support = () => {
  const { mutate: sendMessage, isPending } = useSendSupportMessage();
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      !isPending && sendMessage(values);
      resetForm();
      navigate("/support");
    } catch (error) {
      throw error;
    } finally {
      setSubmitting(false);
    }
  };

  var supportForm = [
    {
      label: "First Name",
      type: "text",
      name: "firstName",
      placeholder: "Enter your first name",
    },
    {
      label: "Last Name",
      type: "text",
      name: "lastName",
      placeholder: "Enter your last name",
    },
    {
      label: "Email",
      type: "email",
      name: "email",
      placeholder: "Enter your email address",
    },
  ];
  return (
    <div className="bg-[#F0F2F5] md:h-screen p-6">
      <div>
        <TopSection content={topContent} />
      </div>
      <div className="my-10 bg-white p-8 rounded-lg w-full md:w-3/5">
        <div className={styles["support__top"]}>
          <h3 className={styles["support__top--3"]}>
            Have a question or concern?
          </h3>
          <h3 className={styles["support__top--3"]}>
            Share your thoughts with us.
          </h3>
        </div>
        <Formik
          initialValues={defaultFormFields}
          validationSchema={validate}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, handleChange, handleBlur }) => (
            <Form>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-4">
                {supportForm.map((el, i) => {
                  const { label, name, type, placeholder } = el;
                  return (
                    <FormInput
                      label={label}
                      name={name}
                      type={type}
                      key={i}
                      className="w-full rounded-lg"
                      placeholder={placeholder}
                    />
                  );
                })}
                {/* <div className={styles["support__btm--top"]}>
                  <div className={styles["support__btm--names"]}>
                    <label
                      className={styles["support__btm--label"]}
                      htmlFor="firstName"
                    >
                      First Name
                    </label>
                    <input
                      className={styles["support__btm--input"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="firstName"
                      type="text"
                      name="firstName"
                      placeholder="Enter your First Name"
                      value={values.firstName}
                    />
                  </div>
                  <div className={styles["support__btm--names"]}>
                    <label
                      className={styles["support__btm--label"]}
                      htmlFor="lastName"
                    >
                      Last Name
                    </label>
                    <input
                      className={styles["support__btm--input"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="lastName"
                      type="text"
                      name="lastName"
                      placeholder="Enter your Last Name"
                      value={values.lastName}
                    />
                  </div>
                </div>
                <div className={styles["support__btm--inner"]}>
                  <label
                    className={styles["support__btm--label"]}
                    htmlFor="email"
                  >
                    Email Address
                  </label>
                  <input
                    className={styles["support__btm--input"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Enter your Email Address"
                    value={values.email}
                  />
                </div> */}
                <PhoneInput name="phoneNumber" />
                {/* <div className={styles["support__btm--inner"]}>
                  <PhoneInput name="phoneNumber" />
                </div> */}
                <div className="md:col-span-2 text-left">
                  <label
                    className="my-4 text-gray-600 text-left"
                    htmlFor="message"
                  >
                    Message
                  </label>
                  <textarea
                    className="w-full rounded-lg"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="message"
                    type="text"
                    name="message"
                    rows={5}
                    value={values.message}
                  />
                </div>
              </div>
              <div className="w-full md:w-3/5 lg:w-2/5 mx-auto my-4">
                <div className={styles["support__btm--check"]}>
                  <input
                    className={styles["support__btm--check-inp"]}
                    checked={values.agreement}
                    onChange={() => setFieldValue("agreement", !values.check)}
                    onBlur={handleBlur}
                    type="checkbox"
                    id="agreement"
                    name="agreement"
                  />
                  <label
                    className={styles["support__btm--check-lab"]}
                    htmlFor="agreement"
                  >
                    You agree to our friendly
                    <a href="/support"> privacy policy.</a>
                  </label>
                </div>
                <Button
                  buttonType={BUTTON_TYPE_CLASSES.curved}
                  isLoading={isPending}
                  type="submit"
                  className="w-full mt-6"
                >
                  Send Message
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Support;

import React, { useEffect, useMemo, useState } from "react";
import styles from "./filter.module.scss";
import FormDropDown from "../../components/atoms/form-dropdown/form-dropDown.component";
import PopupLayout from "../../layouts/popup.layout/property-modal/popup-modal.component";
import { useStore } from "../../../store";
import { useGetLogCategories } from "../../../hooks/activity-log";
import { useGetLogActivities } from "../../../hooks/activity-log";
import { useGetTeamMembersList } from "../../../hooks/team-members";
import { useGetPropertiesList } from "../../../hooks/properties";
import { mapOptions } from "../../pages/dashboard/record-time/utils";

var formContent = {
  heading: "Filter Logs",
  buttonText: "Filter",
  personalGrid: null,
  form: [
    {
      label: "Start Date: ",
      placeholder: "yyyy/MM/dd",
      type: "date",
      name: "startDate",
      required: false,
    },
    {
      label: "End Date: ",
      placeholder: "yyyy/MM/dd",
      type: "date",
      name: "endDate",
      required: false,
    },
  ],
};

const filterRadioData = [
  {
    labelName: "Supporting Document Uploaded",
    value: "doc",
  },
  {
    labelName: "Supporting Document Not Uploaded",
    value: "no_doc",
  },
  {
    labelName: "Both",
    value: "both",
  },
];

const defaultFormFields = {
  startDate: "",
  endDate: "",
};

const FilterModal = ({ handleClose, handleFilter }) => {
  const [showActivity, setshowActivity] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [activitiesOptions, setActivitiesOptions] = useState([]);

  const { rentalType } = useStore();

  const {
    clientData: { logData },
  } = useGetLogCategories();

  const {
    clientData: { activities },
  } = useGetLogActivities();

  const {
    clientData: { teamMembers },
  } = useGetTeamMembersList();
  const {
    clientData: { properties },
  } = useGetPropertiesList({ propertyType: rentalType });

  const handleNextPage = async (values, { setSubmitting, resetForm }) => {
    if (handleFilter) {
      handleFilter(values);
    }
    resetForm();
    setTimeout(() => {
      setSubmitting(false);
    }, 4000);
  };

  const teamMembersOptions = useMemo(
    () => mapOptions(teamMembers),
    [teamMembers]
  );
  const propertiesOptions = useMemo(() => mapOptions(properties), [properties]);

  const commonDropdown = useMemo(
    () => [
      {
        category: "Select Team Member",
        initial: "member",
        optionList: teamMembersOptions,
      },
      {
        category: "Select Property",
        initial: "property",
        optionList: propertiesOptions,
      },
    ],
    [teamMembersOptions, propertiesOptions]
  );

  const logActivitiesOptions = useMemo(
    () => mapOptions(activities),
    [activities]
  );

  useEffect(() => {
    if (logData) {
      var logTypeData = logData.find(
        (el) => el.logTypeValue.toLowerCase() === "real_estate"
      );

      var categoriesOptions = mapOptions(
        logTypeData?.categories?.map((category) => {
          return { name: category.name, id: category.id };
        })
      );
      setCategoriesData(logTypeData?.categories);
      setCategoriesOptions(categoriesOptions);
    }
  }, [setCategoriesData, setCategoriesOptions, logData]);

  const handleCategoryChange = (newValue) => {
    var category = categoriesData.find((el) => el.id === newValue);

    var activitiesOptions = mapOptions(
      category?.activities?.map((activity) => {
        return { name: activity.name, id: activity.id };
      })
    );
    setActivitiesOptions(activitiesOptions);
    setshowActivity(true);
  };

  return (
    <div className={styles["container"]}>
      <PopupLayout
        postChildren={
          <div>
            <div>
              {commonDropdown.map((el, i) => {
                const { optionList, category, initial } = el;
                return (
                  <FormDropDown
                    optionList={optionList}
                    labelName={category}
                    initialName={initial}
                    className={styles["entry__dropdown--form"]}
                    key={i}
                  />
                );
              })}
              {rentalType.toLowerCase() === "str" ? (
                <FormDropDown
                  optionList={logActivitiesOptions}
                  labelName="Select Activity"
                  initialName="activity"
                  className={styles["filter__dropdown--form"]}
                  key="str_activity"
                />
              ) : (
                categoriesOptions && (
                  <FormDropDown
                    optionList={categoriesOptions}
                    labelName="Select Category"
                    initialName="category"
                    className={styles["filter__dropdown--form"]}
                    changeFn={handleCategoryChange}
                    key="ltr_category"
                  />
                )
              )}
              {showActivity && (
                <FormDropDown
                  optionList={activitiesOptions}
                  labelName="Select Activity"
                  initialName="activity"
                  className={styles["filter__dropdown--form"]}
                  key="ltr_activity"
                />
              )}
            </div>
            <div className={styles["filter__btm--checkbox"]}>
              {filterRadioData.map((el, i) => {
                const { value, labelName } = el;
                return (
                  <div key={i} className={styles["filter__btm--check"]}>
                    <input
                      className={styles["filter__btm--check-inp"]}
                      type="radio"
                      id={`docFilter-${i}`}
                      name="docFilter"
                      value={value}
                    />
                    <label
                      className={styles["filter__btm--check-lab"]}
                      htmlFor={`docFilter-${i}`}
                    >
                      {labelName}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        }
        content={formContent}
        initialValue={defaultFormFields}
        handleSubmit={handleNextPage}
        handleClose={handleClose}

        // error={authStore.errorMessage}
      />
    </div>
  );
};

export default FilterModal;

import React, { useEffect, useState } from "react";
import Button, {
  BUTTON_TYPE_CLASSES,
} from "../../../../components/atoms/button/button.component";
import styled from "styled-components";
import styles from "./subscription-manager.module.scss";
import { GiCheckMark } from "react-icons/gi";
import { loadStripe } from "@stripe/stripe-js";
import { useGetSubscriptions } from "../../../../../hooks/subscriptions";
import base from "../../../../../libs/axios";
import { useGetUser } from "../../../../../hooks/user";
import { useStore } from "../../../../../store";
import { SUBSCRIPTION_MANAGE } from "../../../../../constants/permissions";
import { showToast } from "../../../../components/atoms/showToast/showToast";
import { NotificationTypes } from "../../../../constants";

export const SubButton = styled(Button)`
  width: 288px;
  height: 44px;
  border-radius: 50px;
  align-self: left;
  font-size: 16px;
  font-weight: 600;
  font-family: Inter;
  background-color: #fff;
  color: #003889;
  border: 1px solid #003889;

  &:hover {
    background-color: #003889;
    color: #fff;
  }

  &:active {
    background-color: #003889;
    color: #fff;
  }
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionManager = () => {
  const [showSubscriptionPlans, setShowSubscriptionPlans] = useState(true);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [canManageSubscription, setCanManageSubscription] = useState(false);
  const { clientData: data } = useGetSubscriptions({});
  const { data: userData } = useGetUser("");
  const { userPermissions } = useStore();

  useEffect(() => {
    if (userData?.subscription !== null) {
      const endDate = new Date(userData?.subscription?.endDate);
      const now = new Date();

      if (endDate > now) {
        setCurrentSubscription(userData?.subscription);
        setShowSubscriptionPlans(false);
      }

      var manageSubscriptionPermission =
        userPermissions.some((p) => p.value === SUBSCRIPTION_MANAGE) || false;

      setCanManageSubscription(manageSubscriptionPermission);
    }
  }, [userData, userPermissions]);

  var subscriptionData = data?.subscriptions?.map((subscription) => {
    return {
      name: subscription.name,
      btn: "Get Started",
      price: subscription.price,
      index: subscription.id,
      stripePriceId: subscription.stripePriceId,
      features: subscription.features,
    };
  });

  const handleSubscription = async (priceId) => {
    try {
      const response = await base.post(
        `${process.env.REACT_APP_API_URL}api/subscriptions/stripe/create-checkout-session`,
        JSON.stringify({ priceId }),
        {
          headers: {
            "Content-Type": "application/json",
            "pub-access-key": process.env.REACT_APP_PUBLIC_ACCESS_KEY,
          },
        }
      );
      const session = response?.data?.data?.sessionId;

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: session,
      });
      if (error) {
        console.error("Stripe error:", error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const daysOfWeek = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const dayOfWeek = daysOfWeek[date.getDay()];

    const dayOfMonth = date.getDate();
    const suffixes = ["th", "st", "nd", "rd"];
    const relevantDigits = dayOfMonth % 100;
    const suffix =
      relevantDigits >= 11 && relevantDigits <= 13
        ? "th"
        : suffixes[relevantDigits % 10] || "th";

    const months = [
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
    ];
    const month = months[date.getMonth()];

    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    return `${dayOfWeek} ${dayOfMonth}${suffix} ${month} ${year}, ${hours}:${minutes}${ampm}`;
  };

  const handleCancel = async () => {
    try {
      await base.delete(
        `${process.env.REACT_APP_API_URL}api/subscriptions/cancel`,
        {
          headers: {
            "Content-Type": "application/json",
            "pub-access-key": process.env.REACT_APP_PUBLIC_ACCESS_KEY,
          },
        }
      );
      showToast(
        "Subscription cancelled successfully",
        NotificationTypes.SUCCESS
      );
    } catch (error) {
      showToast(
        "Could not cancel subscription. Please try again",
        NotificationTypes.ERROR
      );
      console.error("Error:", error);
    }
  };
  return (
    <div className={styles["sub__div"]}>
      {canManageSubscription && showSubscriptionPlans ? (
        subscriptionData.map((el) => {
          const { index, name, price, features, btn, stripePriceId } = el;
          return (
            <div key={`sub_${index}`} className={styles["sub__card"]}>
              <div className={styles["sub__card--head"]}>
                <h5 className={styles["sub__card--head-5"]}>{name} plan</h5>
                <h3 className={styles["sub__card--head-3"]}>${price}</h3>
                <hr />
              </div>
              <div id={`mid_${index}`} className={styles["sub__card--mid"]}>
                <ul className={styles["sub__card--mid-ul"]}>
                  {features.map((pack, packIndex) => (
                    <li
                      key={`sub_${packIndex}`}
                      className={styles["sub__card--mid-li"]}
                      id={`li_${index}`}
                    >
                      <i className={styles["sub__card--mid-icn"]}>
                        <GiCheckMark />
                      </i>
                      {pack}
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles["sub__card--bottom"]}>
                {stripePriceId && (
                  <SubButton
                    buttonType={BUTTON_TYPE_CLASSES.curved}
                    type="button"
                    className="sub--button"
                    onClick={() => handleSubscription(stripePriceId)}
                  >
                    {btn}
                  </SubButton>
                )}
              </div>
            </div>
          );
        })
      ) : (
        <>
          <div className="w-full flex flex-col bg-white h-2xl p-12 rounded-md shadow-sm">
            {!canManageSubscription && (
              <span className="font-semibold my-4 text-sm text-red-600">
                No active subscription and you cannot manage subscriptions.{" "}
                <br />
                Contact your account admin.
              </span>
            )}
            <div className="flex flex-col md:flex-row mb-4">
              <span className="font-semibold mr-4">Subscription Name:</span>
              <span>{currentSubscription?.name || "N/A"}</span>
            </div>
            <div className="flex flex-col md:flex-row mb-4">
              <span className="font-semibold mr-4">
                Subscription Start Date:
              </span>
              <span className="capitalize">
                {currentSubscription?.startDate
                  ? formatDate(currentSubscription?.startDate)
                  : "N/A"}
              </span>
            </div>
            <div className="flex flex-col md:flex-row mb-4">
              <span className="font-semibold mr-4">Subscription End Date:</span>
              <span className="capitalize">
                {currentSubscription?.endDate
                  ? formatDate(currentSubscription?.endDate)
                  : "N/A"}
              </span>
            </div>

            <div className="w-full my-8">
              <button
                type="button"
                onClick={handleCancel}
                className="bg-business-primary text-white px-6 py-2 hover:bg-white hover:text-business-primary transtion-3s rounded-md hover:shadow-md"
              >
                Cancel Subscription
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionManager;

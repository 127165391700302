/* eslint-disable camelcase */

import TeamMembersIconWrapper from "../../../components/atoms/team-members-icon-wrapper/team-members-icon-wrapper.component";
import columnStyles from "../../../components/molecules/tables/table-wrapper/column-styles.module.scss";

export const renderRoles = (roles) =>
  roles?.map((role) => role?.name).join(", ");

export const teamMembersColumns = [
  {
    title: <p className={columnStyles.table_title}>Team Members</p>,
    dataIndex: "name",
    key: "name",
    render: (name) => {
      return <div className={columnStyles.table_content}>{name || "N/A"}</div>;
    },
  },
  {
    title: <p className={columnStyles.table_title}>Email Address</p>,
    dataIndex: "email",
    key: "email",
    render: (email) => {
      return <div>{email || "N/A"}</div>;
    },
  },

  {
    title: <p className={columnStyles.table_title}>Role(s)</p>,
    dataIndex: "roles",
    key: "roles",
    render: (roles) => {
      return (
        <div className={columnStyles.table_content}>
          {renderRoles(roles) || "N/A"}
        </div>
      );
    },
  },

  {
    dataIndex: "id",
    key: "id",
    render: (id, record) => {
      return (
        <div className={columnStyles.table_contentAction}>
          <TeamMembersIconWrapper data={record} id={id} />
        </div>
      );
    },
  },
];
export const teamPendingInvitiationsColumns = [
  {
    title: <p className={columnStyles.table_title}>Email Address</p>,
    dataIndex: "invitedEmail",
    key: "invitedEmail",
    render: (invitedEmail) => {
      return (
        <div className={columnStyles.table_content + "capitalize"}>
          {invitedEmail || "N/A"}
        </div>
      );
    },
  },
  {
    title: <p className={columnStyles.table_title}>Invited By</p>,
    dataIndex: "inviterName",
    key: "inviterName",
    render: (inviterName) => {
      return <div>{`${inviterName}` || "N/A"}</div>;
    },
  },

  {
    title: <p className={columnStyles.table_title}>Role</p>,
    dataIndex: "roleName",
    key: "roleName",
    render: (roleName) => {
      return (
        <div className={columnStyles.table_content}>{roleName || "N/A"}</div>
      );
    },
  },
  {
    title: <p className={columnStyles.table_title}>Date Invited</p>,
    dataIndex: "invitedAt",
    key: "invitedAt",
    render: (invitedAt) => {
      return (
        <div className={columnStyles.table_content}>
          {new Date(invitedAt).toLocaleDateString() || "N/A"}
        </div>
      );
    },
  },
];

export const mobileTeamMembersColumns = [
  { key: "name", label: "Team Member" },
  { key: "email", label: "Email Address" },
  { key: "roles", label: "Role" },
];
export const mobileTeamPendingInvitiationsColumns = [
  { key: "invitedEmail", label: "Email Address" },
  { key: "roleName", label: "Role" },
  { key: "inviterName", label: "Invited By" },
];

export const renderMobileTeamMembersHeader = (header) => header?.label;

export const renderMobileTeamMembersCell = (header, item) => {
  if (header.key === "roles") return renderRoles(item[header.key]);
  return item[header.key];
};

export const renderMobileTeamMembersIcon = (item) => {
  return <TeamMembersIconWrapper data={item} id={item?.id} />;
};

import React, { useState, useEffect } from "react";

const Timer = ({ onSubmit, handleTimeChange }) => {
  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    let timer;
    if (running) {
      timer = setInterval(() => {
        setTime((prevTime) => {
          var newTime = prevTime + 1;
          handleTimeChange(newTime);
          return newTime;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [running, handleTimeChange]);

  const startTimer = () => {
    setRunning(true);
  };

  const stopTimer = () => {
    setRunning(false);
  };

  const resetTimer = () => {
    setTime(0);
    setRunning(false);
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours}:${minutes}:${seconds}`;
  };

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     const hours = Math.floor(time / 3600);
  //     const minutes = Math.floor((time % 3600) / 60);
  //     const seconds = time % 60;

  //     onSubmit({ hours, minutes, seconds });
  //     stopTimer();
  //     resetTimer();
  //   };

  return (
    <div>
      <div className="w-full text-3xl font-bold">{formatTime(time)}</div>
      <div className="flex flex-row gap-4">
        <button
          type="button"
          className="w-1/3 p-2 bg-green-600 my-3 rounded-lg text-white font-semibold"
          onClick={startTimer}
        >
          Start
        </button>
        <button
          type="button"
          className="w-1/3 p-2 bg-red-600 my-3 rounded-lg text-white font-semibold"
          onClick={stopTimer}
        >
          Stop
        </button>
        <button
          type="button"
          className="w-1/3 p-2 bg-blue-600 my-3 rounded-lg text-white font-semibold"
          onClick={resetTimer}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default Timer;

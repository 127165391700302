import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { getUserPermissions } from "../services/user";
import { LocalStorageKeys } from "../app/constants";

// Get the initial rental type from localStorage
const getInitialRentalType = () => {
  const storedState = localStorage.getItem("rentalType");
  return storedState ? JSON.parse(storedState) : "str";
};

// Create a context Object
const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  // Memoize the initial rental type to avoid unnecessary re-computation
  const initialRentalType = useMemo(() => getInitialRentalType(), []);

  const [rentalType, setRentalType] = useState(initialRentalType);
  const [userPermissions, setUserPermissions] = useState([]);
  const [isPermissionsLoading, setIsPermissionsLoading] = useState(true);

  useEffect(() => {
    // Check if permissions are already cached
    const cachedPermissions = localStorage.getItem(
      LocalStorageKeys.PERMISSIONS
    );

    if (cachedPermissions) {
      setUserPermissions(JSON.parse(cachedPermissions));
      setIsPermissionsLoading(false);
    } else {
      // Fetch permissions if not cached
      const fetchUserPermissions = async () => {
        try {
          const data = await getUserPermissions();
          const permissions = data?.permissions || [];
          setUserPermissions(permissions);
          localStorage.setItem(
            LocalStorageKeys.PERMISSIONS,
            JSON.stringify(permissions)
          );
        } catch (error) {
          console.error("Failed to fetch user permissions:", error);
          setUserPermissions([]);
        } finally {
          setIsPermissionsLoading(false);
        }
      };
      fetchUserPermissions();
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      LocalStorageKeys.RENTAL_TYPE,
      JSON.stringify(rentalType)
    );
  }, [rentalType]);

  const toggleRentalType = useCallback(
    (propertyType) => {
      if (rentalType !== propertyType) {
        setRentalType(propertyType);
      }
    },
    [rentalType]
  );

  return (
    <StoreContext.Provider
      value={{
        rentalType,
        toggleRentalType,
        userPermissions,
        isPermissionsLoading,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => {
  const contextValue = useContext(StoreContext);
  return {
    rentalType: contextValue?.rentalType || "str", // Default rental type
    toggleRentalType: contextValue?.toggleRentalType || (() => {}), // Default empty function
    userPermissions: contextValue?.userPermissions || [],
    isPermissionsLoading: contextValue?.isPermissionsLoading || false,
  };
};

import base from "../../libs/axios";

export const getProperties = (params = {}) => {
  const { pageNumber = 1, pageSize = 10, propertyType = "ALL" } = params;

  let queryString = `/api/properties?PageNumber=${pageNumber}&PageSize=${pageSize}&propertyType=${propertyType}`;

  return base
    .get(queryString)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error retrieving properties:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const getPropertiesList = (params = {}) => {
  const { propertyType = "ALL" } = params;

  let queryString = `/api/properties/list?propertyType=${propertyType}`;

  return base
    .get(queryString)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error retrieving properties:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const getSomeProperties = (keyword, params = {}) => {
  const { propertyType } = params;

  return base
    .get(
      `/api/properties/search?keyword=${keyword}&propertyType=${propertyType}`
    )
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error retrieving properties:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const getProperty = (id) =>
  base
    .get(`/api/properties/${id}`)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error retrieving property:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });

export const createProperty = (data) =>
  base
    .post("/api/properties", data)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error creating property:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });

export const editProperty = ({ id, name, address, propertyType }) => {
  const data = { name, address, propertyType };
  return base
    .patch(`/api/properties/${id}`, data)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error updating property:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const deleteProperty = (id) => {
  return base
    .delete(`/api/properties/${id}`)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error deleting property:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

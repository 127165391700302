import React, { useEffect, useState } from "react";
import TopSection from "../../../components/molecules/top-section/top-section.component";
import styles from "./logs.module.scss";
import UserContext from "../../../../hooks/userContext";
import FilterModal from "../../../modals/filter/filter.component";
import { useGetLogs, useGetSomeLogs } from "../../../../hooks/activity-log";
import TableWrapper from "../../../components/molecules/tables/table-wrapper/table-wrapper.component";
import {
  logsColumns,
  mobileLogsColumns,
  renderMobileLogsCell,
  renderMobileLogsHeader,
  renderMobileLogsIcon,
} from "./data";
import { useStore } from "../../../../store";
import Spinner from "../../../components/atoms/spinner/spinner.component";
import { BUTTON_TYPE_CLASSES } from "../../../components/atoms/button/button.component";
import {
  downloadDocuments,
  downloadReport,
} from "../../../../services/activity-log";
import { showToast } from "../../../components/atoms/showToast/showToast";
import { NotificationTypes } from "../../../constants";

var topContent = {
  dashboardPage: "Logs",
  searchComponent: true,
  searchTitle: "Search",
  filterButton: true,
  timer: null,
};

const Logs = () => {
  const [show, setShow] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isPageLoading, setPageLoading] = useState(false);
  const [searchKeyword, setsearchKeyword] = useState("");
  const [logsTransformed, setLogsTransformed] = useState([]);
  const [otherQuery, setOtherQuery] = useState({ startDate: "" });
  const { rentalType } = useStore();

  // NOTE: for filter, pass filter state values to useGetLogs
  const { clientData: data, isLoading } = useGetLogs({
    pageNumber,
    pageSize,
    propertyType: rentalType,
    ...otherQuery,
  });

  const { clientData: searchData } = useGetSomeLogs(
    searchKeyword,
    rentalType.toUpperCase()
  );

  const { logs, count, currentPage } = data || {};

  const transformLogs = (logs) => {
    return logs.map((log) => {
      return {
        id: log.id,
        serviceType: log?.logType,
        activityDate: new Date(log.activityDate).toLocaleDateString(),
        activity: log.activity?.name,
        timeSpent: `${padNum(log.hoursSpent, 0, 2)}:${padNum(
          log.minutesSpent,
          0,
          2
        )}:${padNum(log.secondsSpent, 0, 2)}`,
      };
    });
  };

  useEffect(() => {
    setLogsTransformed(
      logs.map((log) => {
        return {
          id: log.id,
          serviceType: log?.logType,
          activityDate: new Date(log.activityDate).toLocaleDateString(),
          activity: log.activity?.name,
          timeSpent: `${padNum(log.hoursSpent, 0, 2)}:${padNum(
            log.minutesSpent,
            0,
            2
          )}:${padNum(log.secondsSpent, 0, 2)}`,
        };
      })
    );

    setPageSize(10);
  }, [logs]);

  const handleShow = () => {
    setShow(true);
  };

  const handleShowClose = () => {
    setShow(false);
  };

  const handlePageNavigation = (page) => {
    setPageNumber(page);
  };

  const padNum = (num, padChar, padCount = 1, fromStart = true) => {
    return fromStart
      ? String(num).padStart(padCount, padChar)
      : String(num).padEnd(padCount, padChar);
  };

  const handleSearch = async (keyword) => {
    try {
      setPageLoading(true);
      setsearchKeyword(keyword);
      console.log(searchData);
      let props = keyword
        ? transformLogs(searchData?.searchData ?? [])
        : transformLogs(logs);
      setLogsTransformed(props ?? []);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleFilter = async (queryObj) => {
    setOtherQuery(queryObj);
    setShow(false);
  };

  const handleExportAsXcel = async () => {
    setPageLoading(true);

    try {
      var response = await downloadReport({
        pageNumber,
        pageSize,
        propertyType: rentalType,
        ...otherQuery,
      });
      const href = URL.createObjectURL(await response);
      const link = document.createElement("a");
      const contentDisposition = response.headers?.get("Content-Disposition");

      let fileName = "750hrsTracker_ActivityLog.xlsx";

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename=([^;]*)/);
        if (fileNameMatch && fileNameMatch.length > 0) {
          fileName = fileNameMatch[1];
        }
      }

      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (err) {
      console.log(err);
      showToast(
        `Error downloading documents => ${err?.response?.data?.message}`,
        NotificationTypes.ERROR
      );
    }
    setPageLoading(false);
  };

  const handleSupportingDocumentDownload = async () => {
    setPageLoading(true);

    try {
      var response = await downloadDocuments();

      console.log(response);
      const zipBlob = new Blob([await response], { type: "application/zip" });
      const href = URL.createObjectURL(zipBlob);
      const link = document.createElement("a");
      const contentDisposition = response.headers?.get("Content-Disposition");

      let fileName = "750hrsTracker_ActivityLogDocuments.zip";

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename=([^;]*)/);
        if (fileNameMatch && fileNameMatch.length > 0) {
          fileName = fileNameMatch[1];
        }
      }

      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (err) {
      console.log(err);
      showToast(
        `Error downloading documents => ${err?.response?.data?.message}`,
        NotificationTypes.ERROR
      );
    }
    setPageLoading(false);
  };
  if (isPageLoading) {
    return <Spinner />;
  }
  return (
    <UserContext.Provider value={handleShow}>
      {show && (
        <FilterModal
          handleClose={handleShowClose}
          handleFilter={handleFilter}
        />
      )}
      <div className={styles["logs__main"] + " md:h-screen"}>
        <div>
          <TopSection
            content={topContent}
            handleFilter={handleShow}
            handleSearch={handleSearch}
          />
        </div>

        <div className="download-template flex flex-row justify-end w-full text-right">
          <button
            buttonType={BUTTON_TYPE_CLASSES.curved}
            onClick={handleExportAsXcel}
            type="submit"
            className="bg-business-primary hover:bg-business-secondary text-white font-semibold px-4 py-2 rounded-lg mr-2"
          >
            Export Records (Excel)
          </button>
          <button
            buttonType={BUTTON_TYPE_CLASSES.curved}
            onClick={handleSupportingDocumentDownload}
            type="submit"
            className="bg-business-primary hover:bg-business-secondary text-white font-semibold px-4 py-2 rounded-lg"
          >
            Download All Supporting Documents
          </button>
        </div>
        <div className="my-10 bg-white p-8 rounded-lg">
          <TableWrapper
            currentPage={parseInt(currentPage)}
            dataSource={logsTransformed}
            desktopColumns={logsColumns}
            handlePageNavigation={handlePageNavigation}
            loading={isLoading}
            mobileColumns={mobileLogsColumns}
            pageSize={pageSize}
            renderItemHeaderCell={renderMobileLogsHeader}
            renderItemValueCell={renderMobileLogsCell}
            renderMobileIcon={renderMobileLogsIcon}
            totalCount={count}
          />
        </div>
      </div>
    </UserContext.Provider>
  );
};

export default Logs;

import axios from "axios";
import { LocalStorageKeys } from "../app/constants";

const baseBlob = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "pub-access-key": process.env.REACT_APP_PUBLIC_ACCESS_KEY,
  },
  responseType: "blob",
});

baseBlob.interceptors.request.use((config) => {
  const token = localStorage.getItem(LocalStorageKeys.TOKEN);

  if (token) config.headers["Authorization"] = `Bearer ${token}`;

  return config;
});

export default baseBlob;

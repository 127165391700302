import React from "react";
import * as Yup from "yup";
import {
  ConfirmPasswordValidator,
  PasswordValidator,
} from "../../../../../utils/validation/validation.utils";
import styles from "./security-settings.module.scss";
import FormInput from "../../../../components/atoms/form-input/form-input.component";
import { Formik, Form } from "formik";
import DoubleBtn from "../../../../components/molecules/double-btn/double-btn.component";
import { LocalStorageKeys } from "../../../../constants";

const formData = [
  {
    label: "Old Password",
    placeholder: "Enter old password",
    type: "password",
    name: "oldPassword",
  },
  {
    label: "New Password",
    placeholder: "Enter new password",
    type: "password",
    name: "newPassword",
  },
  {
    label: "Confirm New Password",
    placeholder: "Enter password confirmation",
    type: "password",
    name: "confirmNewPassword",
  },
];

const defaultFormFields = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const validationSchema = Yup.object({
  oldPassword: PasswordValidator,
  newPassword: PasswordValidator,
  confirmNewPassword: ConfirmPasswordValidator,
});

const SecuritySettings = ({ isPending, changePassword }) => {
  const user = JSON.parse(localStorage.getItem(LocalStorageKeys.USER));

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      !isPending &&
        changePassword(
          { values, id: user?.id },
          {
            onSuccess: () => {
              resetForm();
            },
          }
        );
    } catch (error) {
      throw error;
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div className={styles["container"]}>
      <Formik
        initialValues={defaultFormFields}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <div className="m-8 w-1/3 bg-white p-8 rounded-md">
              <div className={styles["form__head"]}>
                <h4 className="font-bold text-business-primary text-lg">
                  Change Password
                </h4>
              </div>
              <div className={styles["form__div--inner"]}>
                {formData.map((el, i) => {
                  const { label, name, placeholder, type } = el;
                  return (
                    <div
                      className={styles["form__div--comp"]}
                      key={`column_${name}${i}`}
                    >
                      <FormInput
                        className="w-full rounded-md h-12"
                        label={label}
                        name={name}
                        type={type}
                        placeholder={placeholder}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="flex justify-center my-8">
                <DoubleBtn
                  firstbtn="Cancel"
                  secondbtn="Save Changes"
                  isSubmitting={isSubmitting}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SecuritySettings;

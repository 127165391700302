import React, { useState } from "react";
import SideNav from "../../components/molecules/sidenav/sidenav.component";
// import styles from "./dashboard.module.scss";
import { Outlet } from "react-router-dom";
import Header from "../../components/molecules/header/header.component";

const Dashboard = (props) => {
  // const [activeRoute, setActiveRoute] = useState('/main-dashboard');
  // const [activeComponent, setActiveComponent] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const openSidebar = (value) => {
    value ? setSidebarOpen(false) : setSidebarOpen((preVal) => !preVal);
  };

  // const handleNavItemClick = (component) => {
  //   // setActiveRoute(route)
  //   setActiveComponent(component);
  // };

  return (
    <>
      <div className="flex h-auto md:h-screen overflow-hidden">
        <SideNav showSidebar={openSidebar} navValue={sidebarOpen} />
        <div className="flex flex-col flex-1 w-0 overflow-hidden">
          <Header showSidebar={openSidebar} />
          <main className="relative flex-1 overflow-y-auto focus:outline-none">
            <div className="w-full">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
      {/* <div className={styles["dashboard"]}>
        <div className={styles["dashboard__left"]}>
          <SideNav onNavItemClick={handleNavItemClick} />
        </div>
        <div className={styles["dashboard__right"]}>
          <Outlet />
        </div>
      </div> */}
    </>
  );
};

export default Dashboard;

import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { useViewport } from "../../../../hooks/useViewPort";
import { ErrorMessage, useField } from "formik";
import {
  FormGroup,
  // FormInputLabel,
  // FormField,
  // FormFieldDiv,
} from "./form-input.styles";
// import { FiEye, FiEyeOff } from "react-icons/fi";

export const ErrorText = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
  color: red;
`;

const FormInput = ({ label, className, onChange, changeFn, ...otherProps }) => {
  const { mobile } = useViewport();
  const [field, meta, helpers] = useField(otherProps);
  const [isPasswordVisible, setPasswordVisible] = useState(true); // State for password visibility
  // const togglePasswordVisibility = () => setPasswordVisible(!isPasswordVisible); // Function to toggle password visibility
  const fieldName = field?.name;

  const handleBlur = useCallback(() => helpers.setTouched(true), [helpers]);

  const handleChange = useCallback(
    (e) => {
      helpers.setValue(e.target.value);

      if (changeFn) {
        changeFn(e);
      }
    },
    [helpers, changeFn]
  );

  return (
    <FormGroup>
      {label && (
        <label
          shrink={field.value?.length}
          for={field.name}
          class="text-750hrs-form-label font-normal opacity-75 mx-1 my-2"
        >
          {label}
        </label>
      )}
      {fieldName === "password" ||
      fieldName === "confirmPassword" ||
      fieldName === "newPassword" ||
      fieldName === "oldPassword" ? (
        <div className="flex flex-row w-full">
          <input
            {...otherProps}
            type={isPasswordVisible ? "password" : "text"}
            className={className}
            error={meta.touched && meta.error}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {/* <i className="form__field--icon" onClick={togglePasswordVisibility}>
            {isPasswordVisible ? <FiEyeOff /> : <FiEye />}
          </i> */}
        </div>
      ) : (
        <input
          {...otherProps}
          error={meta.touched && meta.error}
          className={className}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
      <ErrorMessage name={field?.name}>
        {(msg) => <ErrorText id="text">{msg}</ErrorText>}
      </ErrorMessage>
    </FormGroup>
  );
};

export default FormInput;

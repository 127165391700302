import base from "../../libs/axios";

export const getTeamMembersList = () => {
  let queryString = `/api/team/get-users-once`;

  return base
    .get(queryString)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error getting team members:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};
export const getTeamMembers = (params = {}) => {
  const { pageNumber = 1, pageSize = 10 } = params;

  let queryString = `/api/team/get-users?PageNumber=${pageNumber}&PageSize=${pageSize}`;

  return base
    .get(queryString)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error getting team members:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const getPendingInvitations = (params = {}) => {
  const { pageNumber = 1, pageSize = 10 } = params;

  let queryString = `/api/team/pending-invitation?PageNumber=${pageNumber}&PageSize=${pageSize}`;

  return base
    .get(queryString)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error getting team pending invitation:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const getRoles = (params = {}) => {
  const { pageNumber = 1, pageSize = 10 } = params;

  let queryString = `/api/team/roles?PageNumber=${pageNumber}&PageSize=${pageSize}`;

  return base
    .get(queryString)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error getting roles:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const inviteTeamMember = (data) =>
  base
    .post(`/api/team/invite-user`, data)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error creating inviting new team member:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });

export const createTeamMember = (data) =>
  base
    .post(`/api/team/create-invited-user`, data)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error creating team member:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });

export const getPermissions = (params = {}) => {
  const { pageNumber = 1, pageSize = 10 } = params;

  let queryString = `/api/permissions?PageNumber=${pageNumber}&PageSize=${pageSize}`;

  return base
    .get(queryString)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error getting permissions:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const editTeamMember = (data) => {
  return base
    .patch(`/api/team/update-user-role`, data)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error updating team member:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

export const deleteTeamMember = (id) => {
  return base
    .delete(`/api/team/roles/${id}`)
    .then(({ data }) => data)
    .catch((err) => {
      console.error(
        "Error deleting team member:",
        err?.response?.data?.message || err?.message
      );
      throw err;
    });
};

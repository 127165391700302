import React, { useEffect, useState } from "react";
import TopSection from "../../../components/molecules/top-section/top-section.component";
import styles from "./main-dashboard.module.scss";
// import {
//   dashboardExport,
//   secondDashboardExport,
// } from "../../../assets/data/data";
import { useStore } from "../../../../store";
import TableWrapper from "../../../components/molecules/tables/table-wrapper/table-wrapper.component";
import {
  logsColumns,
  mobileLogsColumns,
  renderMobileLogsCell,
  renderMobileLogsHeader,
  renderMobileLogsIcon,
} from "../logs/data";
import { useGetChartData } from "../../../../hooks/dashboard";
import ChartCards from "../../../components/atoms/charts/chart-cards/chart-cards.component";

var topContent = {
  dashboardPage: null,
  searchComponent: false,
  searchTitle: null,
  filterButton: false,
  timer: null,
};

const MainDashboard = () => {
  const { rentalType } = useStore();

  const [years, setYears] = useState([]);

  const { isLoading: isChartLoading, data: chartData } = useGetChartData({
    propertyType: rentalType,
  });

  const padNum = (num, padChar, padCount = 1, fromStart = true) => {
    return fromStart
      ? String(num).padStart(padCount, padChar)
      : String(num).padEnd(padCount, padChar);
  };
  let chartDataTransformed = chartData
    ? chartData.data.recentLogs.map((log) => {
        return {
          id: log.id,
          serviceType: log?.logType,
          activityDate: new Date(log.activityDate).toLocaleDateString(),
          activity: log.activity?.name,
          timeSpent: `${padNum(log.hoursSpent, 0, 2)}:${padNum(
            log.minutesSpent,
            0,
            2
          )}:${padNum(log.secondsSpent, 0, 2)}`,
        };
      })
    : [];

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const earliestYear = 2020;
    const yearsArray = [];

    for (let year = currentYear; year >= earliestYear; year--) {
      yearsArray.push(year);
    }

    setYears(yearsArray);
  }, []);

  return (
    <div>
      <div>
        <TopSection content={topContent} />
      </div>
      <div className="px-8 py-12 bg-[#F0F2F5]">
        <div className="bg-white p-4 rounded-lg border-1 border-gray-600">
          <div className={styles["main__dashboard--top"]}>
            <h3 className="font-semibold text-lg md:text-2xl">
              Dashboard Overview
            </h3>
            <div className={styles["main__date--div"]}>
              <select
                className={styles["main__date--dropdown"]}
                id="date-dropdown"
              >
                {years.map((year, index) => (
                  <option
                    key={index}
                    value={year}
                    selected={index === 0 ? true : false}
                  >
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles["main__dashboard--bottom"]}>
            {/* pass data as props to ChartCards */}
            <ChartCards chartData={chartData} />
          </div>
        </div>
        <div className="my-10 bg-white p-8 rounded-lg">
          <TableWrapper
            dataSource={chartDataTransformed}
            desktopColumns={logsColumns}
            loading={isChartLoading}
            mobileColumns={mobileLogsColumns}
            renderItemHeaderCell={renderMobileLogsHeader}
            renderItemValueCell={renderMobileLogsCell}
            renderMobileIcon={renderMobileLogsIcon}
            totalCount={chartDataTransformed.length}
            title="Recent Logs"
          />
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;

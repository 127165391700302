import React, { useCallback, useEffect, useMemo, useState } from "react";
import TopSection from "../../../components/molecules/top-section/top-section.component";
import styles from "./edit-log.module.scss";
import FileUpload from "../../../components/atoms/file-upload/file-upload.component";
import Button, {
  BUTTON_TYPE_CLASSES,
} from "../../../components/atoms/button/button.component";
import styled from "styled-components";
import FormDropDown from "../../../components/atoms/form-dropdown/form-dropDown.component";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import FormInput from "../../../components/atoms/form-input/form-input.component";
import { useStore } from "../../../../store";
import {
  useGetLogActivities,
  useGetLogCategories,
  useEditLog,
} from "../../../../hooks/activity-log";
import { propertyType } from "../../../constants";
import { useGetTeamMembersList } from "../../../../hooks/team-members";
import { useGetPropertiesList } from "../../../../hooks/properties";
import { mapOptions } from "./utils";
import { useNavigate, useParams } from "react-router-dom";
import { getLog } from "../../../../services/activity-log";
import Spinner from "../../../components/atoms/spinner/spinner.component";
import { ACTIVITYLOG_UPDATE } from "../../../../constants/permissions";

var topContent = {
  dashboardPage: "Edit Log Details",
  searchComponent: false,
  searchTitle: null,
  filterButton: false,
  timer: true,
};

var myform = [
  {
    label: "Date",
    type: "date",
    name: "activityDate",
  },
];
var timeSpentForm = [
  {
    label: "Hours",
    type: "hoursSpent",
    name: "hoursSpent",
  },
  {
    label: "Minutes",
    type: "minutesSpent",
    name: "minutesSpent",
  },
  {
    label: "Seconds",
    type: "secondsSpent",
    name: "secondsSpent",
  },
];

export const UploadButton = styled(Button)`
  width: 213px;
  height: 49px;
  border-radius: 50px;
  align-self: left;
  margin-top: 3rem;
  margin-left: 25%;
  font-size: 18px;
  font-weight: 500;
`;

const EditLog = observer(() => {
  const { userPermissions } = useStore();

  const { logId } = useParams();

  const [details, setDetails] = useState({});
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { rentalType } = useStore();
  const [uploadedFileCount, setUploadedFileCount] = useState(0);

  const [isRealEstate, setIsRealEstate] = useState(false);

  const [showActivityCategory, setshowActivityCategory] = useState(false);
  const [showActivity, setshowActivity] = useState(false);
  const [showTasks, setShowTask] = useState(false);

  const [categoriesData, setCategoriesData] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  const [activitiesData, setActivitiesData] = useState([]);
  const [activitiesOptions, setActivitiesOptions] = useState([]);

  const [taskOptions, setTasksOptions] = useState([]);

  const [uploadFile, setUploadFile] = useState(false);
  const [files, setFiles] = useState([]);

  const [hasEditPermission, setHasEditPermission] = useState(true);

  useEffect(() => {
    var editPermission =
      userPermissions.some((p) => p.value === ACTIVITYLOG_UPDATE) || false;

    setHasEditPermission(editPermission);
  }, [userPermissions]);

  const today = new Date().toISOString().split("T")[0];

  const toggleFileUpload = () => {
    setUploadFile(!uploadFile);
  };
  const handleFileUpload = (count) => {
    setUploadedFileCount(count);
  };

  const { editLog, isPending } = useEditLog();
  const {
    clientData: { teamMembers },
  } = useGetTeamMembersList();
  const {
    clientData: { properties },
  } = useGetPropertiesList({ propertyType: rentalType });

  const {
    clientData: { logData },
  } = useGetLogCategories();

  const {
    clientData: { activities },
  } = useGetLogActivities();

  const teamMembersOptions = useMemo(
    () => mapOptions(teamMembers),
    [teamMembers]
  );
  const propertiesOptions = useMemo(() => mapOptions(properties), [properties]);
  const logActivitiesOptions = useMemo(
    () => mapOptions(activities),
    [activities]
  );

  const timeCategoryOptions = mapOptions(
    logData?.map((logtype) => {
      return {
        name: logtype.logType,
        id: logtype.logTypeValue.toUpperCase(),
      };
    })
  );

  const commonDropdown = useMemo(
    () => [
      {
        category: "team members",
        initial: "activityById",
        optionList: teamMembersOptions,
      },
    ],
    [teamMembersOptions]
  );

  const timeCategoryDropdownOptions = useMemo(
    () => [
      {
        label: "time category",
        fieldName: "logType",
        optionList: timeCategoryOptions,
      },
    ],
    [timeCategoryOptions]
  );

  const categoriesDropdownOptions = useMemo(
    () => [
      {
        label: "activity category",
        fieldName: "activityLogCategoryId",
        optionList: categoriesOptions,
      },
    ],
    [categoriesOptions]
  );

  const activitiesDropdownOptions = useMemo(
    () => [
      {
        label: "activity",
        fieldName: "activityLogActivityId",
        optionList: activitiesOptions,
      },
    ],
    [activitiesOptions]
  );

  const tasksDropdownOptions = useMemo(
    () => [
      {
        label: "task",
        fieldName: "taskId",
        optionList: taskOptions,
      },
    ],
    [taskOptions]
  );

  const propertiesDropdownOptions = useMemo(
    () => [
      {
        label: "property",
        fieldName: "propertiesIds",
        optionList: propertiesOptions,
      },
    ],
    [propertiesOptions]
  );

  const STRDropdownOptions = useMemo(
    () => [
      {
        category: "activity",
        initial: "activityLogActivityId",
        optionList: logActivitiesOptions,
      },
      {
        category: "property",
        initial: "propertiesIds",
        optionList: propertiesOptions,
      },
    ],
    [logActivitiesOptions, propertiesOptions]
  );

  const renderInitialLtrData = useCallback(
    (values, setFieldValue) => {
      const handleTimeCategoryChange = (newValue) => {
        if (newValue.toLowerCase() === "real_estate") {
          setIsRealEstate(true);
          var logTypeData = logData.find(
            (el) => el.logTypeValue.toLowerCase() === newValue.toLowerCase()
          );

          var categoriesOptions = mapOptions(
            logTypeData.categories?.map((category) => {
              return { name: category.name, id: category.id };
            })
          );
          setCategoriesData(logTypeData.categories);
          setCategoriesOptions(categoriesOptions);
        } else {
          setshowActivityCategory(false);
          setshowActivity(false);
          setShowTask(false);
          setIsRealEstate(false);
        }
      };
      const handleCategoryChange = (newValue) => {
        var category = categoriesData.find((el) => el.id === newValue);

        var activitiesOptions = mapOptions(
          category.activities?.map((activity) => {
            return { name: activity.name, id: activity.id };
          })
        );
        setActivitiesData(category.activities);
        setActivitiesOptions(activitiesOptions);
      };
      const handleActivityChange = (newValue) => {
        var activity = activitiesData.find((el) => el.id === newValue);

        var taskOptions = mapOptions(
          activity.tasks?.map((task) => {
            return { name: task.name, id: task.id };
          })
        );
        setTasksOptions(taskOptions);
      };

      const timeCategoryOpts = timeCategoryDropdownOptions.map((el, i) => {
        const { optionList, label, fieldName } = el;
        return (
          <>
            <FormDropDown
              optionList={optionList}
              labelName={label}
              initialName={fieldName}
              className={styles["entry__dropdown--form"]}
              changeFn={handleTimeCategoryChange}
              value={details[fieldName]}
            />
          </>
        );
      });

      const categoriesOpts = categoriesDropdownOptions.map((el, i) => {
        const { optionList, label, fieldName } = el;
        return (
          <>
            <FormDropDown
              optionList={optionList}
              labelName={label}
              initialName={fieldName}
              className={styles["entry__dropdown--form"]}
              changeFn={handleCategoryChange}
              value={details[fieldName]}
            />
          </>
        );
      });

      const activitiesOpts = activitiesDropdownOptions.map((el, i) => {
        const { optionList, label, fieldName } = el;
        return (
          <>
            <FormDropDown
              optionList={optionList}
              labelName={label}
              initialName={fieldName}
              className={styles["entry__dropdown--form"]}
              changeFn={handleActivityChange}
              value={details[fieldName]}
            />
          </>
        );
      });

      const taskOpts = tasksDropdownOptions.map((el, i) => {
        const { optionList, label, fieldName } = el;
        return (
          <>
            <FormDropDown
              optionList={optionList}
              labelName={label}
              initialName={fieldName}
              className={styles["entry__dropdown--form"]}
              value={details[fieldName]}
            />
          </>
        );
      });

      return (
        <>
          {timeCategoryOpts}
          {showActivityCategory && categoriesOpts}
          {showActivity && activitiesOpts}
          {showTasks && taskOpts}
        </>
      );
    },
    [
      timeCategoryDropdownOptions,
      categoriesDropdownOptions,
      activitiesDropdownOptions,
      tasksDropdownOptions,
      activitiesData,
      categoriesData,
      logData,
      showActivityCategory,
      showActivity,
      showTasks,
      details,
    ]
  );

  const propertyOpts = useMemo(() => {
    if (isRealEstate) {
      return propertiesDropdownOptions.map((el, i) => (
        <FormDropDown
          key={i} // Add a unique key for each component in a list
          optionList={el.optionList}
          labelName={el.label}
          initialName={el.fieldName}
          className={styles["entry__dropdown--form"]}
          value={details[el.fieldName]}
        />
      ));
    }
    return null; // Return null if isRealEstate is false
  }, [propertiesDropdownOptions, isRealEstate, details]);

  const handleSubmit = useCallback(
    async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const { propertiesIds } = values;

      const requestData = {
        ...values,
        propertiesIds: propertiesIds ? [propertiesIds] : [],
        propertyType: propertyType[rentalType],
      };

      if (uploadFile) {
        requestData["supportingDocuments"] = files;
      }

      try {
        !isPending && (await editLog(logId, requestData));

        resetForm();
        navigate("/logs");
      } catch (err) {
      } finally {
        setSubmitting(false);
      }
    },
    [editLog, rentalType, isPending, logId, navigate, files, uploadFile]
  );

  const handleDownloadFile = () => {};
  const setInitialValues = async (logId, logData) => {
    setLoading(true);
    try {
      const res = await getLog(logId);

      var activityDate = new Date(res.data?.activityDate)
        .toISOString()
        .split("T")[0];

      var details = {
        activityDate: activityDate || "",
        hoursSpent: res.data?.hoursSpent,
        minutesSpent: res.data?.minutesSpent,
        secondsSpent: res.data?.secondsSpent,
        description: res.data?.description || "sample",
        activityById: res.data?.activityBy?.id || null,
        activityLogActivityId: res.data?.activity?.id || null,
        activityLogCategoryId: res.data?.category?.id || null,
        propertiesIds: res.data?.properties ? res.data.properties[0]?.id : null,
        taskId: res.data?.task?.id || null,
        evidence: res.data?.supportingDocuments,
        logType: res.data?.logType,
      };

      if (
        res.data?.supportingDocuments &&
        res.data?.supportingDocuments.length > 0
      ) {
        setUploadFile(true);
        setUploadedFileCount(res.data?.supportingDocuments.length);
        setFiles(res.data?.supportingDocuments);
      }

      if (res.data?.logType === "REAL_ESTATE") {
        setIsRealEstate(true);

        // set initial category data
        var logTypeData = logData?.find(
          (el) =>
            el.logTypeValue.toLowerCase() === res.data?.logType.toLowerCase()
        );

        var categoriesOptions = mapOptions(
          logTypeData?.categories?.map((category) => {
            return { name: category.name, id: category.id };
          })
        );
        setCategoriesData(logTypeData?.categories);
        setCategoriesOptions(categoriesOptions);

        // set initial activity data
        var category = logTypeData?.categories?.find(
          (el) => el.id === res.data?.category.id
        );

        var activitiesOptions = mapOptions(
          category?.activities?.map((activity) => {
            return { name: activity.name, id: activity.id };
          })
        );
        setActivitiesData(category?.activities);
        setActivitiesOptions(activitiesOptions);

        // set initial task data
        var activity = category?.activities.find(
          (el) => el.id === res.data?.activity.id
        );

        var taskOptions = mapOptions(
          activity?.tasks?.map((task) => {
            return { name: task.name, id: task.id };
          })
        );
        setTasksOptions(taskOptions);

        // set fields to visible
        setshowActivity(true);
        setshowActivityCategory(true);
        setShowTask(true);
      }
      setDetails(details);
    } finally {
      setLoading(false);
    }
  };

  // Set initial values dynamically based on details
  useEffect(() => {
    const initialValues = async () => {
      await setInitialValues(logId, logData);
    };

    initialValues();
  }, [logId, logData]);

  if (isLoading) {
    return <Spinner />;
  }

  const handleFileChange = (event) => {
    const file = event.target.value;
    const { name, type } = file;
    const nameSplit = name.split(".");
    const fileName = name;
    const contentType = type;
    const fileExtension = nameSplit[1];

    convertFileToBase64(file)
      .then((base64) => {
        const base64String = base64;

        let newFile = {
          timeAdded: new Date().getTime(),
          fileName,
          fileExtension,
          contentType,
          data: base64String,
        };

        setFiles((prevObjs) => [...prevObjs, newFile]);
        setUploadedFileCount(files.length);
      })
      .catch((error) => {
        console.error("Error converting file to Base64:", error);
      });
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = (error) => {
        reject("Error converting file to Base64: " + error);
      };
    });
  };
  const handleRemoveFile = (id) => {
    setFiles((prevFiles) =>
      prevFiles.filter((fileItem) => {
        if (fileItem.documentId) {
          return fileItem.documentId !== id;
        } else {
          return fileItem.timeAdded !== id;
        }
      })
    );
    setUploadedFileCount((prevCount) => prevCount - 1);
  };

  // const hasPermission = useCallback(
  //   (permission) => {
  //     return userPermissions.some((p) => p.value === permission) || false;
  //   },
  //   [userPermissions]
  // );

  return (
    <div className={styles["entry__main--div"]}>
      <TopSection content={topContent} />
      <div className="w-full p-8 md:w-4/5 lg:w-3/5 mx-auto">
        <Formik
          initialValues={details}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange, setFieldValue }) => (
            <Form className={styles["entry__form"]}>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-4">
                {myform.map((el, i) => {
                  const { label, name, type } = el;
                  return (
                    <FormInput
                      label={label}
                      name={name}
                      type={type}
                      key={i}
                      className={styles["entry__field--form-input"]}
                      value={values[name]}
                      max={type === "date" ? today : ""}
                    />
                  );
                })}

                <div className="flex flex-row gap-2">
                  {timeSpentForm.map((el, i) => {
                    const { name, type, label } = el;
                    return (
                      <FormInput
                        label={label}
                        name={name}
                        type={type}
                        key={`${name}-${i}`}
                        className={styles["entry__field--form-input"]}
                        value={values[name]}
                      />
                    );
                  })}
                </div>

                {commonDropdown.map((el, i) => {
                  const { optionList, category, initial } = el;
                  return (
                    <FormDropDown
                      optionList={optionList}
                      labelName={category}
                      initialName={initial}
                      className={styles["entry__dropdown--form"]}
                      value={values[initial]}
                    />
                  );
                })}

                {/* add str / ltr related form inputs */}
                {rentalType === "ltr" ? (
                  <>
                    {renderInitialLtrData(logData)}
                    {propertyOpts}
                  </>
                ) : (
                  STRDropdownOptions.map((el, i) => {
                    const { optionList, category, initial } = el;
                    return (
                      <FormDropDown
                        optionList={optionList}
                        labelName={category}
                        initialName={initial}
                        className={styles["entry__dropdown--form"]}
                        value={values[initial]}
                      />
                    );
                  })
                )}
                <div className="md:col-span-2">
                  <label className="my-4 text-gray-600">
                    Description of activity
                  </label>
                  <textarea
                    placeholder="Describe activity here"
                    name="description"
                    onChange={handleChange}
                    rows="8"
                    cols="55"
                    className="w-full rounded-lg"
                    value={values.description}
                  ></textarea>
                </div>

                <div className="flex items-center mb-4">
                  <label class="inline-flex items-center cursor-pointer">
                    <input
                      id="toggleFileUpload"
                      type="checkbox"
                      onChange={toggleFileUpload}
                      checked={uploadFile}
                      className="sr-only peer"
                    />
                    <div class="relative w-11 h-6 bg-gray-600 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-business-primary dark:peer-focus:ring-business-primary rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-business-primary"></div>
                    <span class="ms-3 text-md font-semibold text-gray-900 dark:text-gray-300">
                      Attach Supporting Document
                    </span>
                  </label>
                </div>
                {uploadFile && (
                  <div className="md:col-span-2">
                    <h4>Supporting Document {uploadedFileCount}/10</h4>
                    <FileUpload
                      className="w-full rounded-lg my-2"
                      onFileUpload={handleFileUpload}
                      fileType="SVG, PNG, JPG, PDF or GIF(max. 800x400px) "
                      name="evidence"
                      fileMax={10}
                      handleChange={handleFileChange}
                      accept="application/pdf, image/*"
                    />
                  </div>
                )}
              </div>
              {uploadFile && (
                <div className="flex flex-col my-4 w-full">
                  <p className="font-semibold text-business-primary text-md">
                    Uploaded Files
                  </p>
                  <div className="p-1 shadow-sm">
                    <table
                      border="1"
                      cellPadding="10"
                      className="w-full bg-white rounded-md"
                    >
                      <thead>
                        <tr className="text-left">
                          <th>Name</th>
                          <th>Type</th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {files.map((fileData) => {
                          return (
                            <>
                              <tr className="border border-bottom-1 border-gray-200">
                                <td>{fileData.fileName}</td>
                                <td>{fileData.fileExtension}</td>
                                <td className="text-right">
                                  <button
                                    onClick={() =>
                                      handleRemoveFile(
                                        fileData.documentId ||
                                          fileData.timeAdded
                                      )
                                    }
                                    type="button"
                                    class="text-business-primary hover:text-white border border-business-primary hover:bg-business-primary focus:ring-4 focus:outline-none focus:ring-business-primary font-medium rounded-lg text-sm px-3 py-1.5 text-center me-2 mb-2 dark:border-business-primary dark:text-business-primary dark:hover:text-white dark:hover:bg-business-primary dark:focus:ring-business-primary"
                                  >
                                    Remvoe
                                  </button>

                                  {/* <button
                                    onClick={() =>
                                      handleDownloadFile(
                                        fileData.documentId ||
                                          fileData.timeAdded
                                      )
                                    }
                                    type="button"
                                    class="text-business-primary hover:text-white border border-business-primary hover:bg-business-primary focus:ring-4 focus:outline-none focus:ring-business-primary font-medium rounded-lg text-sm px-3 py-1.5 text-center me-2 mb-2 dark:border-gray-400 dark:border-4 dark:text-gray-300 dark:hover:text-white dark:hover:bg-business-primary dark:focus:ring-gray-400"
                                  >
                                    Download
                                  </button> */}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {hasEditPermission && (
                <div className="w-full md:w-3/5 mx-auto my-8 lg:w-2/5">
                  <Button
                    buttonType={BUTTON_TYPE_CLASSES.curved}
                    isLoading={isSubmitting}
                    type="submit"
                    className={styles["entry__bottom--btn"] + " rounded-lg"}
                  >
                    Save
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
});

export default EditLog;

import React, { useState } from "react";
import TopSection from "../../../components/molecules/top-section/top-section.component";
import styles from "./team-members.module.scss";
import UserContext from "../../../../hooks/userContext";
import TableWrapper from "../../../components/molecules/tables/table-wrapper/table-wrapper.component";
import { useGetPendingInvitation } from "../../../../hooks/team-members";
import {
  mobileTeamPendingInvitiationsColumns,
  teamPendingInvitiationsColumns,
} from "./data";

var topContent = {
  dashboardPage: "Pending Invitations",
  searchComponent: false,
  searchTitle: null,
  filterButton: false,
  timer: null,
};

const PendingInvitation = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(5);

  const { clientData: data, isLoading } = useGetPendingInvitation({
    pageNumber,
    pageSize,
  });

  const { teamMembers, count, currentPage } = data || {};

  const handlePageNavigation = (page) => {
    setPageNumber(page);
  };

  return (
    <UserContext.Provider>
      <div className={styles["pending_invitation__container"] + " md:h-screen"}>
        <div>
          <TopSection content={topContent} />
        </div>
        <div className="my-10 bg-white p-8 rounded-lg">
          <div>
            <TableWrapper
              currentPage={parseInt(currentPage)}
              dataSource={teamMembers || []}
              desktopColumns={teamPendingInvitiationsColumns}
              handlePageNavigation={handlePageNavigation}
              loading={isLoading}
              mobileColumns={mobileTeamPendingInvitiationsColumns}
              pageSize={pageSize}
              totalCount={count}
            />
          </div>
        </div>
      </div>
    </UserContext.Provider>
  );
};

export default PendingInvitation;
